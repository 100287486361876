import { useEffect, useState } from "react"
import { TalentModel } from "../../../../domain/talent-utils"
import Layout from "../../../../layout"
import { getTalentsByWerkvloerbegeleider } from "../../../../domain/werkvloerbegeleider-utils"
import { useParams } from "react-router-dom"
import Talenten from "../../../../components/subnav/talenten/talenten"
import { useAuth } from "../../../../context/AuthContext"

export default function Details() {

  const [talenten, setTalenten] = useState<TalentModel[]>([])

  const { user } = useAuth()

  const { id } = useParams()

  const user2 = {
    name: 'Jan van Gullik',
    role: 'Schoonmaakbaas',
    imageUrl:
      'https://i.scdn.co/image/ab67616d00001e02d4979556125d84b3ade2ee2b',
  }
  const stats = [
    { label: 'Plekken beschikbaar', value: 12 },
    { label: 'Aangemeld', value: 4 },
    { label: 'Ziek', value: 2 },
  ]

  useEffect(() => {
    getTalentsByWerkvloerbegeleider(id!, user!).then((werkvloerbegeleider) => {
      setTalenten(werkvloerbegeleider?.talenten!)
    })
  }, [])

  return (
    <Layout>
      <Talenten />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-6">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <h2 id="profile-overview-title" className="sr-only">
            Profile Overview
          </h2>
          <div className="bg-white p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="shrink-0">
                  <img alt="" src={user2.imageUrl} className="mx-auto size-20 rounded-full" />
                </div>
                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p className="text-sm font-medium text-gray-600">Caseload van:</p>
                  <p className="text-xl font-bold text-gray-900 sm:text-2xl">{user2.name}</p>
                  <p className="text-sm font-medium text-gray-600">{user2.role}</p>
                </div>
              </div>
              <div className="mt-5 flex justify-center sm:mt-0">
                <a
                  href="#"
                  className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Bericht sturen
                </a>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
            {stats.map((stat) => (
              <div key={stat.label} className="px-6 py-5 text-center text-sm font-medium">
                <span className="text-gray-900">{stat.value}</span> <span className="text-gray-600">{stat.label}</span>
              </div>
            ))}
          </div>
        </div>


        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Title
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {talenten.map((talent) => (
                    <tr key={talent.email}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img alt="" src={talent.foto} className="h-11 w-11 rounded-full" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{talent.volledige_naam}</div>
                            <div className="mt-1 text-gray-500">{talent.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">Title</div>
                        <div className="mt-1 text-gray-500">Department</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          Active
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{talent.rol}</td>
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Edit<span className="sr-only">, {talent.volledige_naam}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
