import { CheckIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'

const _steps = [
  { name: 'Create account', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'complete' },
  {
    name: 'Profile information',
    description: 'Cursus semper viverra facilisis et et some more.',
    href: '#',
    status: 'current',
  },
  { name: 'Business information', description: 'Penatibus eu quis ante.', href: '#', status: 'upcoming' },
  { name: 'Theme', description: 'Faucibus nec enim leo et.', href: '#', status: 'upcoming' },
  { name: 'Preview', description: 'Iusto et officia maiores porro ad non quas.', href: '#', status: 'upcoming' },
]

type ProgressItem = {
  name: string,
  status: string,
  date: Date
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function Example({ traject, lopendTraject }) {

  const steps: ProgressItem[] = []

  if (traject && lopendTraject?.begindatum) {
    let startDate = dayjs(lopendTraject?.begindatum)
    let endDate = dayjs(startDate).add(traject?.duur ?? 0, 'month')
    let differenceStartCurrentDate = dayjs().diff(startDate, 'day')
    let differenceStartEndDate = endDate.diff(startDate, 'day')

    steps.push({
      name: "Start traject",
      status: "complete",
      date: startDate.toDate()
    }, {
      name: "Ontplooiing",
      status: (endDate.toDate() < dayjs().toDate()) ? "complete" : "future",
      date: endDate.toDate()
    },
    )

    let praktijkVerklaring = traject.praktijkverklaring
    let loonwaardeMeting = traject.loonwaardemeting
    let talentPortfolio = traject.talentportfolio

    if (praktijkVerklaring) {
      steps.push({
        name: "Praktijkverklaring",
        status: (endDate.subtract(1, 'month').toDate() < dayjs().toDate()) ? "complete" : "future",
        date: endDate.subtract(1, 'month').toDate()
      })
    }

    if (loonwaardeMeting) {
      steps.push({
        name: "Loonwaardemeting",
        status: (endDate.subtract(1, 'month').toDate() < dayjs().toDate()) ? "complete" : "future",
        date: endDate.subtract(1, 'month').toDate()
      })
    }

    if (talentPortfolio) {
      steps.push({
        name: "Talentportfolio",
        status: (endDate.subtract(1, 'month').toDate() < dayjs().toDate()) ? "complete" : "future",
        date: endDate.subtract(1, 'month').toDate()
      })
    }

    let aantalRapportages = traject.aantal_rapportages
    let intervalRapportages = (aantalRapportages <= 0) ? 0 : differenceStartEndDate / (aantalRapportages + 1)
    let aantalEvaluatieMomenten = traject.evaluatie_momenten
    let intervalEvaluaties = (aantalEvaluatieMomenten <= 0) ? 0 : differenceStartEndDate / (aantalEvaluatieMomenten + 1)
    let aantalJobcoachInterventies = traject.jobcoach_interventies
    let intervalJobcoachInterventies = (aantalJobcoachInterventies <= 0) ? 0 : differenceStartEndDate / (aantalJobcoachInterventies + 1)

    for (let i = 1; i <= aantalRapportages; i++) {
      steps.push({
        name: i + "e rapportage",
        status: ((intervalRapportages * i) >= differenceStartCurrentDate) ? "future" : "complete",
        date: startDate.add(intervalRapportages * i, 'day').toDate()
      })
    }

    // komt eraan, is geweest, gedaan, niet gedaan

    for (let i = 1; i <= aantalEvaluatieMomenten; i++) {
      steps.push({
        name: i + "e evaluatiemoment",
        status: ((intervalEvaluaties * i) >= differenceStartCurrentDate) ? "future" : "complete",
        date: startDate.add(intervalEvaluaties * i, 'day').toDate()
      })
    }

    for (let i = 1; i <= aantalJobcoachInterventies; i++) {
      steps.push({
        name: i + "e jobcoach interventie",
        status: ((intervalJobcoachInterventies * i) >= differenceStartCurrentDate) ? "future" : "complete",
        date: startDate.add(intervalJobcoachInterventies * i, 'day').toDate()
      })
    }

    steps.sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1)

    for (let step of steps) {
      if (step.date >= dayjs().toDate()) {
        step.status = "current"
        break
      }
    }

  }

  // if (talentModel?.lopend_traject?.begindatum) {

  //   console.log(talentModel?.traject?.aantal_rapportages)

  //   // for (var i = 0; i < talentModel?.traject?.aantal_rapportages; i++) {
  //   //   _steps.push((i + 1) + "e Rap")
  //   // }
  // }

  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== _steps.length - 1 ? 'pb-10' : '', 'relative')}>
            {step.status === 'complete' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-sky-400" />
                ) : null}
                <a href="#" className="group relative flex items-start">
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-sky-400 group-hover:bg-sky-800">
                      <CheckIcon aria-hidden="true" className="h-5 w-5 text-white" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.date.toLocaleDateString()}</span>
                  </span>
                  <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ml-4">
                    Afgerond
                  </span>
                </a>
              </>
            ) : step.status === 'current' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" />
                ) : null}
                <a href="#" aria-current="step" className="group relative flex items-start">
                  <span aria-hidden="true" className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-pink-500 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-pink-500" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.date.toLocaleDateString()}</span>
                  </span>
                </a>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" />
                ) : null}
                <a href="#" className="group relative flex items-start">
                  <span aria-hidden="true" className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-gray-500">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.date.toLocaleDateString()}</span>
                  </span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
