import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

interface BreadcrumbProps {
  personName: string;
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ personName }) => {
  const navigate = useNavigate();

  return (
    <div className="sticky top-0 z-50 bg-white px-4 py-3 sm:px-6">
      <div className="flex items-center justify-between">
        {/* Back Button */}
        <button
          type="button"
          onClick={() => navigate('/vandaag')}
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 "
        >
          <ChevronLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          Talenten
        </button>

        {/* Person Name */}
        <h1 className="text-lg font-semibold text-gray-900">{personName}</h1>
      </div>
    </div>
  );
};

export default Breadcrumbs;