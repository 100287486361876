import { Navigate, redirect } from "react-router-dom";
import { AuthProvider, useAuth } from "../../context/AuthContext";
import Navbar from "../../Navbar";
import { Analytics } from "@vercel/analytics/react"
import Tabbarmobile from "../../components/tabbar/folder/Tabbarmobile";


const Layout = ({ children }) => {
  return (
    <>
      <div className="overflow-auto h-screen ">
        <Analytics />
        <main className="mx-auto h-[calc(100vh-65px) max-w-[2160px] bg-gray-50">{children}</main>
      </div>
    </>
  )
}

export default Layout