import { LopendTrajectModel } from '../../domain/lopend-traject-utils'
import { TalentModel } from '../../domain/talent-utils'
import Toggle from '../toggles/toggle/Toggle'
import Trajectprogress from '../traject/trajectprogress/Trajectprogress'
import updateUser from '../../domain/user-utils'

export default function TalentTab(talent: TalentModel, lopendTraject?: LopendTrajectModel | null) {

  const activateringOnChange = () => {
    const val = (!talent?.actief) ? true : !talent.actief
    updateUser(talent.ref, {actief: val}).then((_) => {
      talent.actief = val
      console.log("saved")
    })
  }

  const uitstroomOnChange = () => {
    const val = (!talent?.uitstroom) ? true : !talent.uitstroom
    updateUser(talent.ref, { "talent.uitstroom":  val}).then((_) => {
      talent.uitstroom = val
      console.log("saved")
    })
  }

  return (
    <div className=''>
      {/* <div className=" sm:px-0 grid grid-cols-4">
        <div className='col-span-3'>
          <h3 className="text-base font-semibold leading-7 text-gray-900">Talenprofiel</h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p>
        </div>
        <div className='col-span-1 auto-cols-min flex justify-end'>
          <div className='grid grid-cols-4 '>
            <div className='col-span-1 place-self-end'>
              <p className="mt-1 text-sm leading-6 text-gray-500 pr-2">Activeren</p>
              <Toggle defaultValue={true} onChange={activateringOnChange} />
            </div>
            <div className='col-span-1 place-self-end'>
              <p className="mt-1  text-sm leading-6 text-gray-500">Uitstroom</p>
              <Toggle defaultValue={talent.uitstroom} onChange={uitstroomOnChange} />
            </div>
          </div>
        </div>

      </div> */}
      <div className="mt-1">
        <div className=''>
          <div className="col-span-3">
            <dl className="grid grid-cols-2 sm:grid-cols-3">
              <div className="px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Traject</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.traject?.naam ?? "Onboarding"}</dd>
              </div>
              <div className="px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Casemanager</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{lopendTraject?.consulent?.voornaam}</dd>
              </div>

              <div className="px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Locatie</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{lopendTraject?.werkbedrijf?.naam}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Adres</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.straat} {talent?.huisnummer} {talent?.postcode} {talent?.gemeente}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.email}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Telefoon</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.telefoonnummer}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Adres</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.nationaliteit}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Gemeente</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.gemeente}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Rol</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.rol}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Adres</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.nationaliteit}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Actief</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"><Toggle defaultValue={talent.actief} onChange={activateringOnChange} /></dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Uitstroom</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"><Toggle defaultValue={talent.uitstroom} onChange={uitstroomOnChange} /></dd>
              </div>

              <div className="border-t border-gray-100 px-4 py-6 col-span-2 sm:col-span-3 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Talent samenvatting</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  Yassin Rahmani, geboren op 14 maart 1990, begon op 12 september 2024 aan zijn traject binnen het programma Krachtig in Werk. Met een achtergrond in sportbegeleiding en een gemotiveerde, vriendelijke houding, is hij sinds de start actief in de productiewerkplaats bij Impact Makers. Ondanks persoonlijke uitdagingen en fysieke beperkingen heeft Yassin al veel positieve indrukken achtergelaten bij zijn collega’s en leidinggevenden.

                  Evaluaties en Voortgang

                  Vanaf dag één heeft Yassin aangetoond zich vol enthousiasme in te zetten voor zijn werk. In zijn eerste weken was hij betrokken bij taken zoals het monteren van meubels, het organiseren van magazijnvoorraden, en het opknappen van gebruikte materialen. Deze diverse werkzaamheden hebben hem waardevolle ervaring en meer zelfvertrouwen opgeleverd. Desondanks kwam hij een aantal obstakels tegen: zijn woon-werkverkeer bleek lastig te organiseren, en door chronische knieklachten moest hij soms pauzes nemen. Dankzij de flexibiliteit van zijn team en een vergoeding voor zijn reiskosten wist hij deze problemen op te lossen en zijn focus op het werk te behouden.

                  Tijdens het werk in de opslagruimte op 24 oktober bleek dat Yassin zich goed kon concentreren op taken die nauwkeurigheid en planning vereisen, zoals het op volgorde zetten van voorraadrekken en het uitvoeren van kwaliteitscontroles. Zijn aandacht voor detail zorgde voor een nette en goed georganiseerde ruimte, wat door zijn leidinggevende werd gewaardeerd.

                  Verbeterpunten en Ondersteuning

                  Hoewel Yassin sterk gemotiveerd is, zijn er enkele aandachtspunten die hem kunnen helpen zijn prestaties verder te verbeteren. Een belangrijk verbeterpunt is het afstemmen van zijn werkzaamheden met het team, met name als het gaat om het tijdig doorgeven van zijn beschikbaarheid. Op 25 oktober vergat hij bijvoorbeeld te melden dat hij later zou komen door een medisch onderzoek. Daarnaast kan Yassin ondersteuning gebruiken bij het plannen en uitvoeren van fysiek belastende taken om zijn knieklachten te verlichten. Tijdens een project op 19 oktober, waarbij hij kasten verplaatste, was zijn inzet waardevol, maar door beter te plannen kan hij zijn gezondheid beter beschermen.

                  Toekomstige Stappen en Waardering

                  Yassin toont betrokkenheid bij zijn werk en staat open voor constructieve feedback. Hij heeft al een goede band opgebouwd met zijn collega’s en leidinggevenden, en zijn werkhouding is positief en toegewijd. In de komende maanden zal de nadruk liggen op het verbeteren van zijn communicatieve vaardigheden en het ontwikkelen van een praktische planning voor zijn werkzaamheden. Met gerichte begeleiding op deze punten kan Yassin zijn potentieel beter benutten en zijn traject succesvol afronden.

                  Zijn leerbereidheid en enthousiasme maken Yassin tot een waardevolle kracht binnen Impact Makers. Door gerichte begeleiding en een heldere werkaanpak zal hij in staat zijn om zijn functie optimaal uit te voeren en verder te groeien in zijn rol.

                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
