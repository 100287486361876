import { DocumentReference } from "firebase/firestore"
import { getAllLicentienemerDocuments, getLicentienemerDocumentById, getLicentienemerDocumentByName, RemoteLicentienemer } from "../datasource/licentienemer-utils"
import { getUserById } from "./user-utils"

export type LicentienemerModel = {
    reference: DocumentReference,
    naam: string,
    url_naam: string,
    logo: string,
    titel1: string,
    beschrijving1: string,
    titel2: string,
    beschrijving2: string,
    feature1: string,
    feature_description1: string,
    feature2: string,
    feature_description2: string,
    feature3: string,
    feature_description3: string,
    straat: string,
    nummer: string,
    postcode: string,
    telefoonnummer: string,
    email: string,
    plaats: string,
    contact_persoon?: {
        naam: string
    },
    contact_persoon_id?: string
}

function convertRemoteToModel(remote: RemoteLicentienemer) {
    return {
        reference: remote.reference,
        naam: remote.naam,
        url_naam: remote.url_naam,
        logo: remote.logo,
        titel1: remote.titel1,
        beschrijving1: remote.beschrijving1,
        titel2: remote.titel2,
        beschrijving2: remote.beschrijving2,
        feature1: remote.feature1,
        feature_description1: remote.feature_description1,
        feature2: remote.feature2,
        feature_description2: remote.feature_description2,
        feature3: remote.feature3,
        feature_description3: remote.feature_description3,
        straat: remote.straat,
        nummer: remote.nummer,
        postcode: remote.postcode,
        telefoonnummer: remote.telefoonnummer,
        email: remote.email,
        plaats: remote.plaats
    } as LicentienemerModel
}

export const getAllLicentienemers = async (options?: {contact_persoon: true}) => {

    const list: LicentienemerModel[] = []
    const licentienemers = await getAllLicentienemerDocuments()

    for (const licentienemer of licentienemers) {
        let model = convertRemoteToModel(licentienemer)

        if (options?.contact_persoon) {
            let contactPersoon = await getUserById(licentienemer.contact_persoon)
            model.contact_persoon = {
                naam: `${contactPersoon?.voornaam} ${contactPersoon?.achternaam}`
            }
        }

        list.push(model)
    }

    return list
}

export const getLicentienemerById = async (id?: string) => {

    if(!id) return Promise.reject("Fout.")

    const licentienemer = await getLicentienemerDocumentById(id)

    if(!licentienemer) return Promise.reject("Geen licentienemer gevonden.")

    return convertRemoteToModel(licentienemer)

}

export const getLicentienemerByName = async (name?: string) => {

    if(!name) return Promise.reject("Fout.")

    const licentienemer = await getLicentienemerDocumentByName(name)

    if(!licentienemer) return Promise.reject("Geen licentienemer gevonden.")

    return convertRemoteToModel(licentienemer)

}