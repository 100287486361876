import { collection, DocumentReference, getDoc, getDocs, limit, query, where, doc, QueryDocumentSnapshot, SnapshotOptions, addDoc, orderBy, and, updateDoc } from "firebase/firestore"
import { db } from "../utils/firebase";
import { NewRapportageModel } from "../domain/rapportage-utils";

export type RemoteRapportage = {
    reference: DocumentReference,
    aanmaak_datum: Date,
    auteur_ref: DocumentReference,
    consulent_ref: DocumentReference,
    content: string,
    leidinggevende_ref: DocumentReference,
    soort: string,
    start_datum: Date,
    talent_ref: DocumentReference,
    traject_ref: DocumentReference,
    werkbedrijf_ref: DocumentReference,
    data_eigenaar_ref: DocumentReference
}

const rapportageConverter = {
    toFirestore: (rapportage: RemoteRapportage) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteRapportage {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            aanmaak_datum: data.aanmaak_datum.toDate(),
            auteur_ref: data.auteur_ref,
            consulent_ref: data.consulent_ref,
            content: data.content,
            leidinggevende_ref: data.leidinggevende_ref,
            soort: data.soort,
            start_datum: data.start_datum.toDate(),
            talent_ref: data.talent_ref,
            traject_ref: data.traject_ref,
            werkbedrijf_ref: data.werkbedrijf_ref,
            data_eigenaar_ref: data.data_eigenaar_ref
        }
    }
}

export default async (max: number, dataOwner: DocumentReference) => {
    const q = query(collection(db, "rapportage"), where("data_eigenaar_ref", "==", dataOwner), orderBy("aanmaak_datum", "desc"), limit(max)).withConverter(rapportageConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteRapportage[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getRapportageByTalentRef = async (talentRef: DocumentReference, dataOwner: DocumentReference) => {
    const q = query(collection(db, "rapportage"), and(where("data_eigenaar_ref", "==", dataOwner), where("talent_ref", "==", talentRef))).withConverter(rapportageConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteRapportage[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getRapportageByRef = async (rapportageRef: DocumentReference) => {
    const docSnap = await getDoc(rapportageRef);

    if (docSnap.exists()) {
        return {
            ref: docSnap.ref,
            aanmaakdatum: docSnap.data().aanmaakdatum.toDate(),
            auteur_ref: docSnap.data().auteur_ref,
            bedrijf_naam: docSnap.data().bedrijf_naam,
            consulent_ref: docSnap.data().consulent_ref,
            consultent_naam: docSnap.data().consultent_naam,
            content: docSnap.data().content,
            leidinggevende_naam: docSnap.data().leidinggevende_naam,
            leidinggevende_ref: docSnap.data().leidinggevende_ref,
            soort: docSnap.data().soort,
            startdatum: docSnap.data().startdatum.toDate(),
            talent_naam: docSnap.data().talent_naam,
            talent_ref: docSnap.data().talent_ref,
            traject_naam: docSnap.data().traject_naam,
            traject_ref: docSnap.data().traject_ref,
            werkbedrijf_ref: docSnap.data().werkbedrijf_ref,
            auteur_naam: docSnap.data().auteur_naam,
            data_eigenaar_ref: docSnap.data().data_eigenaar_ref
        }
    } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    }
}

export const getRapportageDocumentById = async (id: string) => {
    const _doc = doc(db, "rapportage", id).withConverter(rapportageConverter);
    const docSnap = await getDoc(_doc);

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    }
}

export const createRapportageDocument = (data: NewRapportageModel, bedrijf: DocumentReference, consulent: DocumentReference, leidinggevende: DocumentReference, traject: DocumentReference, talent: DocumentReference, auteur: DocumentReference) => {
    return addDoc(collection(db, "rapportage"), {
        aanmaak_datum: new Date(),
        auteur_ref: auteur,
        consulent_ref: consulent,
        content: data.content,
        leidinggevende_ref: leidinggevende,
        soort: data.soort,
        start_datum: data.startDatum,
        talent_ref: talent,
        traject_ref: traject,
        werkbedrijf_ref: bedrijf,
        data_eigenaar_ref: data.data_eigenaar
});
}

export const updateRapportageDocumentById = (id: string, content: string) => {
    return updateDoc(doc(db, "rapportage", id), {content: content})
}