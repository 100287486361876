import { collection, query, getDocs, where, limit, DocumentReference, getDoc, doc, getCountFromServer, orderBy, and, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { db } from "../utils/firebase";

export type RemoteTalent = {
    reference: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    talent?: {
        gemeente: string,
        huisnummer: string,
        intake_ref: DocumentReference,
        lopendtraject_ref: DocumentReference,
        nationaliteit: string,
        plaats: string,
        postcode: string,
        straat: string,
        traject_ref: DocumentReference,
        uitstroom: boolean
    },
    telefoon_nummer: string,
    voornaam: string,
    actief?: boolean
};

const talentConverter = {
    toFirestore: (talent: RemoteTalent) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteTalent {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            achternaam : data.achternaam,
                  email :data.email,
                  gebruikers_rol : data.gebruikers_rol,
                  geslacht : data.geslacht,
                  profiel_foto : data.profiel_foto,
                  telefoon_nummer : data.telefoon_nummer,
                  talent : data.talent,
                  voornaam : data.voornaam,
                  actief : data.actief,
        }
    }
}

export default async (max: number, dataOwner: DocumentReference) => {

    const q = query(collection(db, "user"), and(where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "talent")), orderBy("voornaam"), limit(max)).withConverter(talentConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => list.push(doc.data()));

    return list; 
}

export const getTalentDocumentById = async (id: DocumentReference | string ) => {

    if(!id ){
        return null;
    }
    const docSnap =  (typeof id === "string" ) ?await getDoc( doc(db, "user", id.toString()).withConverter(talentConverter)):await getDoc((id as DocumentReference).withConverter(talentConverter));

    /* = await getDoc(id); */
    
    if (docSnap.exists()) {
       return docSnap.data()
      } else {
        return null; 
      }
}
export const getVerjaardagen = async () => {
    const currentDate = new Date();
    const verjaardag = query(collection(db, 'user'), where('geboortedatum', '>', currentDate)).withConverter(talentConverter);

    const querySnapshot = await getDocs(verjaardag );
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 

}

export const getTalentenWithUitstroom = async (dataOwner: DocumentReference) => {
    const q = query(collection(db, "user"), and(where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "talent"), where("talent.uitstroom", "==", true))).withConverter(talentConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 

}

export const getGevoel = async () => {
    const gevoel = query(collection(db, 'mood_talent'), where('gevoel', '<=', 3))
}

//For the filter function
 export const getTalentDocumentsByTraject = async (reference: DocumentReference, dataOwner: DocumentReference) => {

    const q = query(collection(db, 'user'), and(where("data_eigenaar_ref", "==", dataOwner), where('talent.traject_ref', '==', reference)), orderBy("voornaam")).withConverter(talentConverter)
    const trajectQuery = await getDocs(q);

    const list: RemoteTalent[] =  [] 
    trajectQuery.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getCountTalentenByTraject = async (reference: DocumentReference, dataOwner: DocumentReference) => {
    const q = query(collection(db, "user"), and(where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "talent"), where("talent.traject_ref", "==", reference)));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count
}

