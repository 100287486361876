import { collection, query, getDocs, where, limit, DocumentReference, doc, getDoc, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { db } from "../utils/firebase";
import { LicentienemerModel } from "../domain/bedrijf-utils";
export type RemoteBedrijf = {
    reference: DocumentReference,
    huisnummer?: string,
    logo?: string,
    naam?: string,
    plaats?: string,
    postcode?: string,
    soort?: string,
    straat?: string
};

export type RemoteLicentienemer = {
    reference: DocumentReference,
    naam?: string,
    url_naam?: string,
};

const bedrijfConverter = {
    toFirestore: (bedrijf: RemoteBedrijf) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteBedrijf {
            const data = snapshot.data(options)!;
            return {
              reference: snapshot.ref,
              huisnummer: data.huisnummer,
              logo: data.logo,
              naam: data.naam,
              plaats: data.plaats,
              postcode: data.postcode,
              soort: data.soort,
              straat: data.straat,
            }
        }
  }

  const licentienemerConverter = {
    toFirestore: (licentienemer: RemoteLicentienemer) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteLicentienemer {
            const data = snapshot.data(options)!;
            return {
              reference: snapshot.ref,
              naam: data.naam,
              url_naam: data.url_naam
            }
        }
  }

export const getAllBedrijvenDocuments = async (dataOwner: DocumentReference): Promise<RemoteBedrijf[]> => {
    const q = query(collection(db, "bedrijf"), where('data_eigenaar_ref', '==', dataOwner)).withConverter(bedrijfConverter);
    const querySnapshot = await getDocs(q);

    const list: RemoteBedrijf[] = [];
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list;
};

export const getBedrijfDocumentByReference = async (id: string) => {
    const reference = await doc(db, "bedrijf", id).withConverter(bedrijfConverter)
    const docSnap = await getDoc(reference);

    if (!docSnap.exists()) return null

    return docSnap.data()
};

export const getBedrijfReferenceByName = async (name: string) => {
    const q = query(collection(db, "licentienemer"), where('url_naam', '==', name)).withConverter(licentienemerConverter);
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty){
        return Promise.reject("Geen bedrijf gevonden.")
    }

    return querySnapshot.docs[0].data().reference
};

export const getBedrijfReferenceByReference = async (reference: DocumentReference) => {
    const docSnap = await getDoc(reference.withConverter(licentienemerConverter));

    if (!docSnap.exists()) return null

    return docSnap.data()
};

