import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisHorizontalIcon, EnvelopeIcon, PencilIcon, PhoneIcon } from '@heroicons/react/20/solid'
import Layout from '../../layout'
import { Link } from 'react-router-dom'
import { DocumentCheckIcon, PlusIcon } from '@heroicons/react/24/outline'
import { getTrajecten, TrajectModel } from '../../domain/traject-utils'
import { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {

  const [trajecten, setTrajecten] = useState<TrajectModel[]>([])
  const {user} = useAuth()

  useEffect(() => {
    getTrajecten(user!, {includeCount: true}).then((trajecten) => {
      setTrajecten(trajecten)
    })
  },[])

  return (
    <Layout>
      <div className='mx-auto max-w-7xl px-6 lg:px-8 h-auto overflow-y-scroll'>

      <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8 rounded-lg">
        {trajecten.map((traject) => (
          <li key={traject.reference?.id} className="overflow-hidden rounded-lg bg-slate-50">
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 p-6 shadow-inner-2xl">
              <Link to={'/trajecten/detail'}><div className="text-lg font-medium text-gray-900">{traject.naam}</div></Link>
              <Menu as="div" className="relative ml-auto">
                <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Open options</span>
                  <EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5" />
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    <a
                      href={'/trajecten/detail'}
                      className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                    >
                      View
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                    >
                      Edit
                    </a>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>

            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6 ">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Gemeente</dt>
                <dd className="text-gray-700">
                  {traject.gemeente}
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Duur</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="font-medium text-gray-900">{traject.duur}</div>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Contract eigenaar</dt>
                <dd className="flex items-start gap-x-2">
                  <button
                    type="button"
                    className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="flex min-w-0 flex-1 items-center space-x-3">
                      <span className="block shrink-0">
                        <img alt="" className="h-10 w-10 rounded-full" />
                      </span>
                      <span className="block min-w-0 flex-1 pr-4">
                        <span className="block truncate text-sm font-medium text-gray-900">{traject.consulent?.naam}</span>
                        <span className="block truncate text-sm font-medium text-gray-500">{traject.gemeente}</span>
                      </span>
                    </span>

                  </button>
                </dd>
              </div>
              <div>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 mb-5">
                  <div key="talenten" className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                    <dt className="truncate text-sm font-medium text-gray-500">Aantal talenten</dt>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{traject.talentenCount}</dd>
                  </div>
                  <div key="aanmeldingen" className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                    <dt className="truncate text-sm font-medium text-gray-500">Aanmeldingen</dt>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{traject.aanmeldingenCount}</dd>
                  </div>
                </dl>
              </div>
              <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <a
                  href={`/trajecten/detail/${traject.reference?.id}`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <DocumentCheckIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Bekijken
                </a>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <Link
                  to={{pathname: `/beheer/trajecten`}}
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <PencilIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Bewerken
                  </Link>
              </div>
            </div>
            </dl>
          </li>
        ))}
      </ul>
      </div>
    </Layout>
  )
}
