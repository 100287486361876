import { debug } from 'webpack';
import _getTalents, { RemoteTalent, getTalentenWithUitstroom as _getTalentenWithUitstroom, getTalentDocumentsByTraject, getTalentDocumentById } from '../datasource/talent-utils';
import { DocumentReference } from 'firebase/firestore';
import { getTrajectByID } from '../datasource/traject-utils';
import { getBedrijfByReference } from './bedrijf-utils';
import { getLopendTrajectByReference, LopendTrajectModel } from './lopend-traject-utils';
import { getTrajectById, TrajectModel } from './traject-utils';
import { UserModel } from './user-utils';
import { getIntakeById } from './intake-utils';
import { getRapportageByTalentRef } from './rapportage-utils';
import dayjs from 'dayjs';

export type TalentModel = {
  id: string;
  ref: DocumentReference,
  gemeente?: string,
  huisnummer?: string,
  nationaliteit?: string,
  plaats?: string,
  postcode?: string,
  straat?: string,
  telefoonnummer: string,
  voornaam: string,
  achternaam: string,
  volledige_naam: string,
  foto?: string,
  email: string,
  rol: string,
  traject_id?: string,
  traject?: TrajectModel | null,
  lopend_traject_id?: string,
  lopend_traject?: LopendTrajectModel | null,
  consulenten_id?: string,
  intake_id?: string,
  intake?: {
    id: string,
    aanmaak_datum: Date,
  },
  uitstroom?: boolean,
  actief?: boolean
}

function convertRemoteToModel(remote: RemoteTalent) {
  if (!remote) return null

  return {
    id: remote.reference.id,
    ref: remote.reference,
    gemeente: remote.talent?.gemeente,
    huisnummer: remote.talent?.huisnummer,
    nationaliteit: remote.talent?.nationaliteit,
    plaats: remote.talent?.plaats,
    postcode: remote.talent?.postcode,
    straat: remote.talent?.straat,
    telefoonnummer: remote.telefoon_nummer,
    voornaam: remote.voornaam,
    achternaam: remote.achternaam,
    volledige_naam: `${remote.voornaam} ${remote.achternaam}`,
    foto: remote.profiel_foto,
    email: remote.email,
    rol: remote.gebruikers_rol
  } as TalentModel
}

export const getTalents = async (max: number,user: UserModel, options?: {traject?: boolean, lopendTraject?: boolean}) => {
  const list: TalentModel[] = []

  var talenten = await _getTalents(max, user?.data_eigenaar_ref!)

  for(var talent of talenten) {

    let tempModel: TalentModel = {
      id: talent.reference.id,
      ref: talent.reference,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer,
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: `${talent.voornaam} ${talent.achternaam}`,
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol
    }

    if (options?.traject) {
      let traject = await getTrajectByID(talent.talent?.traject_ref)
      tempModel.traject = traject
  } else {
      tempModel.traject_id = talent.talent?.traject_ref?.id
  }

  if (options?.lopendTraject) {
    let lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)
    tempModel.lopend_traject = lopendTraject
} else {
    tempModel.lopend_traject_id = talent.talent?.lopendtraject_ref?.id
}

    list.push(tempModel)
}

  return list;
}

export const getTalentById = async (id?: string, options?: {traject?: boolean, lopendTraject?: boolean, intake?: boolean}) => {
  
  if (!id) return Promise.reject("Geen ID opgegeven.")

  const talent = await getTalentDocumentById(id);

  if (!talent) {
    return Promise.reject("Geen talent gevonden.")
  }

  let tempModel: TalentModel = {
    id: talent.reference.id,
    ref: talent.reference,
    gemeente: talent.talent?.gemeente,
    huisnummer: talent.talent?.huisnummer,
    nationaliteit: talent.talent?.nationaliteit,
    plaats: talent.talent?.plaats,
    postcode: talent.talent?.postcode,
    straat: talent.talent?.straat,
    telefoonnummer: talent.telefoon_nummer,
    voornaam: talent.voornaam,
    achternaam: talent.achternaam,
    volledige_naam: talent.voornaam + " " + talent.achternaam,
    foto: talent.profiel_foto,
    email: talent.email,
    rol: talent.gebruikers_rol,
     uitstroom: talent.talent?.uitstroom,
     actief: talent.actief
  }

  if (options?.traject) {
    let traject = await getTrajectByID(talent.talent?.traject_ref)
    tempModel.traject = traject
} else {
    tempModel.traject_id = talent.talent?.traject_ref?.id
}

if (options?.lopendTraject) {
  let lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)
  tempModel.lopend_traject = lopendTraject
} else {
  tempModel.lopend_traject_id = talent.talent?.lopendtraject_ref?.id

}

if (options?.intake) {
  let intake = await getIntakeById(talent.talent?.intake_ref?.id)
  tempModel.intake = {
    id: intake.id!,
    aanmaak_datum: intake.datumUitgevoerd!,
  }
} else {
  tempModel.intake_id = talent.talent?.intake_ref?.id

}
return tempModel
}

/* Get "Werkvloer begeleider" door specfiek te zoeken naar die rol*/
export const getTalentWithDiffrentRole = async (max: number, rol: string, user: UserModel) => {
  /* GET TalentModel
     Filter voor andere rollen
     en return de value */
     const allTalents = await getTalents(max, user);
     const filteredTalents = allTalents.filter(talent => talent.rol === rol);
     return filteredTalents;
}


export const getTalentByIdWithAdditionalInfo = async (id: string | DocumentReference) => {
  const talent = await getTalentDocumentById(id);

  if (!talent) {
    throw new Error(`Talent met ID ${id.toString()} niet gevonden`);
  }

 /* traject ophahalen voor talent 
Talent model aanmaaken op basis van gegevns van talent en traject */

let traject = await getTrajectById(talent.talent?.traject_ref.id)
let lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)

  return {
    id: talent.reference.id,
    ref: talent.reference,
    gemeente: talent.talent?.gemeente,
    huisnummer: talent.talent?.huisnummer,
    nationaliteit: talent.talent?.nationaliteit,
    plaats: talent.talent?.plaats,
    postcode: talent.talent?.postcode,
    straat: talent.talent?.straat,
    telefoonnummer: talent.telefoon_nummer,
    voornaam: talent.voornaam,
    achternaam: talent.achternaam,
    volledige_naam: talent?.voornaam + " " + talent?.achternaam,
    foto: talent.profiel_foto,
    email: talent.email,
    rol: talent.gebruikers_rol,
    traject: traject,
    lopend_traject: lopendTraject,
    intakeRef: talent.talent?.intake_ref,
    uitstroom: talent.talent?.uitstroom,
    actief: talent.actief
  };
};

export const getTalentWithAdditionalInfo = async (max: number, user: UserModel, options?: {traject?: boolean, lopendTraject?: boolean}) => {

  /* Get talent list
     Get traject from talent ID
     en return it's value*/
  const list: TalentModel[] = []
  const talenten = await _getTalents(max, user?.data_eigenaar_ref!)

  for(var talent of talenten) {

    let tempModel: TalentModel = {
      id: talent.reference.id,
      ref: talent.reference,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer,
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: talent.voornaam + " " + talent.achternaam,
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol
    }

    if (options?.traject) {
      let traject = await getTrajectByID(talent.talent?.traject_ref)
      tempModel.traject = traject
  } else {
      tempModel.traject_id = talent.talent?.traject_ref?.id
  }

  if (options?.lopendTraject) {
    let lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)
    tempModel.lopend_traject = lopendTraject
} else {
    tempModel.lopend_traject_id = talent.talent?.lopendtraject_ref?.id
}

    list.push(tempModel)
  }

  return list;

}

//functie om talent op te halen op basis van speciek traject
export const getTalentsByTraject = async (trajectModel: TrajectModel, user: UserModel, options?: {traject?: boolean, lopendTraject?: boolean}) => {

  const list: TalentModel[] = []

  var talenten = await getTalentDocumentsByTraject(trajectModel.reference!, user?.data_eigenaar_ref!)

  for(var talent of talenten) {

    let tempModel: TalentModel = {
      id: talent.reference.id,
      ref: talent.reference,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer,
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: talent.voornaam + " " + talent.achternaam,
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol
    }

    if (options?.traject) {
      let traject = await getTrajectByID(talent.talent?.traject_ref)
      tempModel.traject = traject
  } else {
      tempModel.traject_id = talent.talent?.traject_ref?.id
  }

  if (options?.lopendTraject) {
    let lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)
    tempModel.lopend_traject = lopendTraject
} else {
    tempModel.lopend_traject_id = talent.talent?.lopendtraject_ref?.id
}

    list.push(tempModel)
  }

  

  return list;

}

export const getTalentenWithUitstroom = async (user: UserModel, options?: {traject?: boolean, lopendTraject?: boolean}) => {
  const talenten = await _getTalentenWithUitstroom(user?.data_eigenaar_ref!)
  const list: TalentModel[] = []
  for (const talent of talenten) {
    let tempModel: TalentModel = {
      id: talent.reference.id,
      ref: talent.reference,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer,
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: talent.voornaam + " " + talent.achternaam,
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol
    }

    if (options?.traject) {
      let traject = await getTrajectByID(talent.talent?.traject_ref)
      tempModel.traject = traject
  } else {
      tempModel.traject_id = talent.talent?.traject_ref?.id
  }

  if (options?.lopendTraject) {
    let lopendTraject = await getLopendTrajectByReference(talent.talent?.lopendtraject_ref)
    tempModel.lopend_traject = lopendTraject
} else {
    tempModel.lopend_traject_id = talent.talent?.lopendtraject_ref?.id
}

    list.push(tempModel)
  }

  return list;
}

export default () => { }

//Get talent by Id
export const getTalentsById = async (id: string | DocumentReference): Promise<TalentModel | null> => {
  try {
    const talent = await getTalentDocumentById(id);

    if (!talent) {
      console.warn(`Talent with ID ${id.toString()} not found.`);
      return null;
    }

    return {
      id: talent.reference.id,
      ref: talent.reference,
      gemeente: talent.talent?.gemeente,
      huisnummer: talent.talent?.huisnummer,
      nationaliteit: talent.talent?.nationaliteit,
      plaats: talent.talent?.plaats,
      postcode: talent.talent?.postcode,
      straat: talent.talent?.straat,
      telefoonnummer: talent.telefoon_nummer, 
      voornaam: talent.voornaam,
      achternaam: talent.achternaam,
      volledige_naam: `${talent.voornaam ?? ''} ${talent.achternaam ?? ''}`.trim(),
      foto: talent.profiel_foto,
      email: talent.email,
      rol: talent.gebruikers_rol,
    };

  } catch (error) {
    console.error(`Error fetching talent by ID ${id.toString()}:`, error);
    return null;
  }
};

export const getDocumentenByTalentId = async (user: UserModel, talent: TalentModel, id: string) => {

  const intake = await getIntakeById(talent.intake_id)
  const rapportages = await getRapportageByTalentRef(user, talent)

  const documenten: {naam: string, aanmaak_datum: Date, id: string, soort: string}[] = []

  if (intake) {
    documenten.push({naam: "Intake", aanmaak_datum: intake.datumUitgevoerd!, id: intake.id!, soort: "intake"})
  }

  for (let rapportage of rapportages) {
    documenten.push({naam: rapportage.soort, aanmaak_datum: rapportage.aanmaakdatum, id: rapportage.id, soort: "rapportage"})
  }

  documenten.sort((a, b) => (dayjs(a.aanmaak_datum).isBefore(dayjs(b.aanmaak_datum)) ? 1 : -1))

  return documenten
  
}


// Check van het lopend_traject Model of een talent een werkbedrijf_ref heeft zo ja vraag van dat bedrijf de locatie op, 
// locatie is een combinatie van "naam", "postcode", "straat" en "plaats"

// export const getWerkbedrijfLocatie = async (lopendTraject: LopendTrajectModel) => {
//   if (lopendTraject.werkbedrijf_ref) {
//     console.log(lopendTraject.werkbedrijf_ref)
//     const bedrijf = await getBedrijfByReference(lopendTraject.werkbedrijf_ref);
//     if (bedrijf) {
//       return {
//         naam: bedrijf.naam,
//         postcode: bedrijf.postcode,
//         straat: bedrijf.straat,
//         plaats: bedrijf.plaats,
//         locatie: bedrijf.plaats + " " + bedrijf.straat + " " + bedrijf.postcode,
//       };
//     }
//   }
//   return null; 
// };
