import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import Layout from '../../../layout'
import { useState, useEffect, useMemo } from 'react'
import getWerkvloerbegeleiders, { getTotalTalentsByWerkvloerbegeleiders, WerkvloerbegeleiderModel } from '../../../domain/werkvloerbegeleider-utils'
import Talenten from '../../../components/subnav/talenten/talenten'
import { useAuth } from '../../../context/AuthContext'

const stats = [
  { name: 'Totaal Talenten', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
  { name: 'Gem. Verdeling', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
  { name: 'Gem. Gevoel', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function States() {
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200  rounded-lg bg-white  md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
              </div>

              <div
                className={classNames(
                  item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon
                    aria-hidden="true"
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                  />
                ) : (
                  <ArrowDownIcon
                    aria-hidden="true"
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                  />
                )}

                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

export default function Caseload() {
  const [werkvloerBegeleiders, setWerkvloerBegeleiders] = useState<WerkvloerbegeleiderModel[]>([])
  const [total, setTotal] = useState(0)

  const {user} = useAuth()

  useEffect(() => {
    getWerkvloerbegeleiders(user!).then((werkvloerBegeleiders) => {
      setWerkvloerBegeleiders(werkvloerBegeleiders)
      getTotalTalentsByWerkvloerbegeleiders(werkvloerBegeleiders, user!).then((total) => { setTotal(total); stats[0].stat = "" + total })
    })
  }, [])

  // useEffect(() => {
  //   getTalentWithDiffrentRole(50, 'werkvloerbegeleider').then((talenten) => {
  //     setTalenten(talenten);
  //   });
  // }, []);

  return (
    <div className=" px-4">
    <Layout>
      <Talenten />
      <div className='mx-auto '>
        <div>
          {States()}
        </div>

        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-6 ">
          {werkvloerBegeleiders.map((werkvloerbegeleider) => (
            <li
              key={werkvloerbegeleider.email}
              className="col-span-1 flex flex-col  rounded-lg bg-white text-center shadow-2xl overflow-hidden"
            >
              <Link to={"/talentenhub/caseload/details/" + werkvloerbegeleider.reference.id}>
                <div className="flex flex-1 flex-col p-8">
                  <img alt="" src={werkvloerbegeleider.profiel_foto} className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" />
                  <h3 className="mt-6 text-sm font-medium text-gray-900">{werkvloerbegeleider.voornaam}&nbsp;{werkvloerbegeleider.achternaam}</h3>
                  <dl className="mt-1 flex flex-grow flex-col justify-between">
                    <dt className="sr-only">Title</dt>
                    <dd className="text-sm text-gray-500">{werkvloerbegeleider.gebruikers_rol}</dd>
                    <dt className="sr-only">Role</dt>
                    <dd className="mt-3">
                      <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {werkvloerbegeleider.gebruikers_rol}
                      </span>
                    </dd>
                  </dl>
                </div>
              </Link>

              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    <a
                      href={`mailto:${werkvloerbegeleider.email}`}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                      Email
                    </a>
                  </div>
                  <div className="-ml-px flex w-0 flex-1">
                    <a
                      href={`tel:${werkvloerbegeleider.telefoon_nummer}`}
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <PhoneIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                      Call
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
    </div>
  )
}

