import { collection, doc, DocumentReference, getDoc, getDocs, query, QueryDocumentSnapshot, SnapshotOptions, where } from "firebase/firestore";
import { db } from "../utils/firebase";

export type RemoteLicentienemer = {
    reference: DocumentReference,
    naam: string,
    url_naam: string,
    logo: string,
    titel1: string,
    beschrijving1: string,
    titel2: string,
    beschrijving2: string,
    feature1: string,
    feature_description1: string,
    feature2: string,
    feature_description2: string,
    feature3: string,
    feature_description3: string,
    straat: string,
    nummer: string,
    postcode: string,
    telefoonnummer: string,
    email: string,
    plaats: string,
    contact_persoon: DocumentReference
}

const licentienemerConverter = {
    toFirestore: (licentienemer: RemoteLicentienemer) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteLicentienemer {
            const data = snapshot.data(options)!;
            return {
                reference: snapshot.ref,
                naam: data.naam,
                url_naam: data.url_naam,
                logo: data.logo,
                titel1: data.titel1,
                beschrijving1: data.beschrijving1,
                titel2: data.titel2,
                beschrijving2: data.beschrijving2,
                feature1: data.feature1,
                feature_description1: data.feature_description1,
                feature2: data.feature2,
                feature_description2: data.feature_description2,
                feature3: data.feature3,
                feature_description3: data.feature_description3,
                straat: data.straat,
                nummer: data.nummer,
                postcode: data.postcode,
                telefoonnummer: data.telefoonnummer,
                email: data.email,
                plaats: data.plaats,
                contact_persoon: data.contact_persoon
            }
        }
  }

  export const getAllLicentienemerDocuments = async () => {
    const q = query(collection(db, "licentienemer")).withConverter(licentienemerConverter)
    const querySnapshot = await getDocs(q);

    const list: RemoteLicentienemer[] = []

    querySnapshot.forEach((doc) => {
        list.push(doc.data());
    });

    return list

  }

export const getLicentienemerDocumentById = async (id?: string) => {
    if (!id) {
        return null;
    }
    const docSnap = await getDoc(doc(db, "licentienemer", id).withConverter(licentienemerConverter));

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null;
    }
  }

  export const getLicentienemerDocumentByName = async (name?: string) => {
    if (!name) {
        return null;
    }

    const q = query(collection(db, "licentienemer"), where("url_naam", "==", name)).withConverter(licentienemerConverter)
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data()
    } else {
        return null;
    }
  }