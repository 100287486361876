import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import Html from "react-pdf-html";

// Registreer het Arial-lettertype
Font.register({
    family: "Arial",
    src: "https://cdn.jsdelivr.net/gh/ryanoasis/nerd-fonts@2.3.3/src/unpatched-fonts/Arial/Regular/Arial.ttf",
  });

const RapportagePDFFile = ({ content }) => {
  if (!content) content = "<html><body><h1>Geen inhoud beschikbaar</h1></body></html>";

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: '8px',
      lineHeight: 1.2,
      padding: 30,
      color: "#333",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20,
      },
      headerInfo: {
        fontSize: 10,
        lineHeight: 1.5,
      },
      logo: {
        width: 40,
        height: 40,
      },
    h1: {
      fontSize: 18,
      fontWeight: "bold",
      color: "#2c3e50",
    },
    h2: {
      fontSize: 14,
      fontWeight: "bold",
      color: "#34495e",
    },
    body: {
        fontSize: 10,
        fontWeight: "bold",
        color: "#34495e",
      },
    footer: {
      position: "absolute",
      bottom: 30,
      left: 30,
      right: 30,
      fontSize: 10,
      textAlign: "center",
      color: "#888",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.header}>
          {/* Document Info */}
          <View style={styles.headerInfo}>
            <Text>Rapportage</Text>
            <Text>Onderwerp: [Onderwerp Titel]</Text>
            <Text>Gegenereerd op: [Datum]</Text>
          </View>
          {/* Logo */}
          <Image src="https://cdn.prod.website-files.com/6706cb25bd13f4bda163d510/6706d034fa779286779767b3_Asset%201%404x.png" style={styles.logo} />
        </View>
        <View>
          <Html style={styles.body}>{content}</Html>
        </View>
        <View style={styles.footer}>
          <Text>© 2024 - Bloeiindex | Vertrouwelijk</Text>
        </View>
      </Page>
    </Document>
  );
};

export default RapportagePDFFile;