import { DocumentReference } from "firebase/firestore"
import getAllEvaluatieDocuments, { getEvaluatiesByTalentId as _getEvaluatiesByTalentId, createEvaluatieDocument } from "../datasource/evaluatie-utils"
import { getUserById, UserModel } from "./user-utils"
import { getTalentById } from "./talent-utils"
import { getTalentDocumentById } from "../datasource/talent-utils"
import { getUserDocumentById } from "../datasource/user-utils"

export type EvaluatieModel = {
  aanmaak_datum: Date,
  auteur: {
    naam: string,
    profiel_foto?: string
  },
  content: string
}

export type NewEvaluatieModel = {
  auteurId: string,
  talentId: string,
  content: string
}

export const getAllEvaluaties = async (user: UserModel, max = 20) => {
  const list: EvaluatieModel[] = []

  const evaluaties = await getAllEvaluatieDocuments(user?.data_eigenaar_ref!, max)

  const users: Map<string, UserModel> = new Map()

  for (let evaluatie of evaluaties) {
    let _auteur
    if (evaluatie.geschreven_door){
    if (users.has(evaluatie.geschreven_door.id)) {
      _auteur = users.get(evaluatie.geschreven_door.id)
    } else {
      _auteur = await getUserById(evaluatie.geschreven_door)
      users.set(evaluatie.geschreven_door.id, _auteur)
    }}
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${_auteur?.voornaam} ${_auteur?.achternaam}`,
        profiel_foto: _auteur?.profiel_foto
      },
      content: evaluatie.omschrijving
    })
  }

  return list

}

export const getEvaluatiesByTalentId = async (id: string, user: UserModel) => {
  const list: EvaluatieModel[] = []

  const talent = await getTalentDocumentById(id)

  if (!talent)
    return Promise.reject("Geen talent gevonden.")

  var evaluaties = await _getEvaluatiesByTalentId(talent.reference, user?.data_eigenaar_ref!)

  const users: Map<string, UserModel> = new Map()

  for (let evaluatie of evaluaties) {
    let _auteur
    if (evaluatie.geschreven_door){
    if (users.has(evaluatie.geschreven_door.id)) {
      _auteur = users.get(evaluatie.geschreven_door.id)
    } else {
      _auteur = await getUserById(evaluatie.geschreven_door)
      users.set(evaluatie.geschreven_door.id, _auteur)
    }}
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${_auteur?.voornaam} ${_auteur?.achternaam}`,
        profiel_foto: _auteur?.profiel_foto
      },
      content: evaluatie.omschrijving
    })
  }

  return list;
}

export const getEvaluatieWithAdditionalInfo = async (id: string, user: UserModel) => {
  const list: EvaluatieModel[] = []

  const talent = await getTalentDocumentById(id)

  if (!talent)
    return Promise.reject("Geen talent gevonden.")

  const evaluaties = await _getEvaluatiesByTalentId(talent.reference, user?.data_eigenaar_ref!)

  for (let evaluatie of evaluaties) {
    var _auteur = await getUserById(evaluatie.geschreven_door)
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${_auteur?.voornaam} ${_auteur?.achternaam}`,
        profiel_foto: _auteur?.profiel_foto
      },
      content: evaluatie.omschrijving
    })
  }

  return list;
}

export const createEvaluatie = async (user: UserModel, data: NewEvaluatieModel) => {
  const talent = await getTalentDocumentById(data.talentId)

  if (!talent) return Promise.reject("Geen talent gevonden.")

  const auteur = await getUserDocumentById(data.auteurId)

  if (!auteur) return Promise.reject("Geen auteur gevonden.")

  return createEvaluatieDocument({auteurReference: auteur.ref, talentReference: talent.reference, content: data.content, dataOwner: user.data_eigenaar_ref!})
}