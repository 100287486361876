import {
  ChevronRightIcon,
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import Talenten from "../../components/subnav/talenten/talenten";
import Layout from "../../layout";
import { getTalents, TalentModel } from "../../domain/talent-utils";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { getProgressItems } from "../../utils/traject-progress-calculator";
import { useNavigate } from "react-router-dom";

export default function Example() {
  const { user } = useAuth();

  const [talenten, setTalenten] = useState<TalentModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const navigate = useNavigate();

  useEffect(() => {
    getTalents(25, user!, { traject: true, lopendTraject: true }).then(
      (response) => {
        setTalenten(response);
        setIsLoading(false);
      }
    );
  }, [user]);

  const onTalentClick = (id: string) => {
    navigate(`talentdetails/${id}`);
  };

  const handleSort = (field) => {
    let order = "asc";
    if (sortField === field && sortOrder === "asc") {
      order = "desc";
    }
    setSortField(field);
    setSortOrder(order);
  };

  function getField(item, field) {
    switch (field) {
      case "talent":
        return item.volledige_naam?.toLowerCase();
      case "traject":
        return item.traject?.naam?.toLowerCase();
      case "status":
        return item.lopend_traject?.status?.toLowerCase();
      case "signaal":
        const progressItem = getProgressItems(
          item.traject,
          item.lopend_traject,
          true
        )[0];
        return progressItem ? progressItem.date.getTime() : 0;
      case "startdatum":
        return item.lopend_traject?.begindatum
          ? item.lopend_traject.begindatum.getTime()
          : 0;
      default:
        return "";
    }
  }

  // Sorteer de talenten
  let sortedTalenten = [...talenten];
  if (sortField) {
    sortedTalenten.sort((a, b) => {
      let aField = getField(a, sortField);
      let bField = getField(b, sortField);
      if (aField == null) aField = "";
      if (bField == null) bField = "";

      if (aField > bField) {
        return sortOrder === "asc" ? 1 : -1;
      }
      if (aField < bField) {
        return sortOrder === "asc" ? -1 : 1;
      }
      return 0;
    });
  }

  // Paginering logica
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTalenten = sortedTalenten.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(sortedTalenten.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Genereer paginanummers
  const pageNumbers:number[] = [];
  const maxPageNumbersToShow = 5;
  let startPage = Math.max(1, currentPage - 2);
  let endPage = Math.min(totalPages, currentPage + 2);

  if (totalPages > maxPageNumbersToShow) {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = maxPageNumbersToShow;
    } else if (currentPage >= totalPages - 2) {
      startPage = totalPages - maxPageNumbersToShow + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  } else {
    startPage = 1;
    endPage = totalPages;
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <Layout>
      <Talenten />
      <div className="mx-auto py-4 max-w-7xl">
        {isLoading ? (
          // Skeleton Loader
          <table className="w-full whitespace-nowrap text-left">
            <colgroup>
              <col className="w-full sm:w-4/12" />
              <col className="lg:w-4/12" />
              <col className="lg:w-2/12" />
              <col className="lg:w-1/12" />
              <col className="lg:w-1/12" />
            </colgroup>
            <thead className="border-b border-gray-200 text-sm">
              <tr>
                <th
                  scope="col"
                  className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8"
                >
                  Talent
                </th>
                <th
                  scope="col"
                  className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                >
                  Traject
                </th>
                <th
                  scope="col"
                  className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20"
                >
                  Signaal
                </th>
                <th
                  scope="col"
                  className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                >
                  Startdatum
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {Array.from({ length: 10 }).map((_, index) => (
                <tr key={index} className="animate-pulse">
                  <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                    <div className="flex items-center gap-x-4">
                      <div className="h-12 w-12 rounded-full bg-gray-200" />
                      <div className="flex-1">
                        <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                        <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                      </div>
                    </div>
                  </td>
                  <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                    <div className="flex gap-x-3">
                      <div className="flex-1">
                        <div className="h-4 bg-gray-200 rounded w-2/3 mb-2"></div>
                        <div className="h-3 bg-gray-200 rounded w-1/3"></div>
                      </div>
                      <div className="h-6 w-16 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="py-4 pl-0 pr-4 text-sm sm:pr-8 lg:pr-20">
                    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                    </div>
                  </td>
                  <td className="hidden py-4 pl-0 pr-8 text-sm md:table-cell lg:pr-20">
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  </td>
                  <td className="hidden py-4 pl-0 pr-4 text-right text-sm sm:table-cell sm:pr-6 lg:pr-8">
                    <div className="h-4 bg-gray-200 rounded w-1/2 ml-auto"></div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>
            <table className="w-full whitespace-nowrap text-left">
              <colgroup>
                <col className="w-full sm:w-4/12" />
                <col className="lg:w-4/12" />
                <col className="lg:w-2/12" />
                <col className="lg:w-1/12" />
                <col className="lg:w-1/12" />
              </colgroup>
              <thead className=" text-sm">
                <tr>
                  <th
                    scope="col"
                    className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8 cursor-pointer"
                    onClick={() => handleSort("talent")}
                  >
                    Talent{" "}
                    {sortField === "talent" &&
                      (sortOrder === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    scope="col"
                    className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell cursor-pointer"
                    onClick={() => handleSort("traject")}
                  >
                    Traject{" "}
                    {sortField === "traject" &&
                      (sortOrder === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    scope="col"
                    className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20 cursor-pointer"
                    onClick={() => handleSort("status")}
                  >
                    Status{" "}
                    {sortField === "status" &&
                      (sortOrder === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    scope="col"
                    className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20 cursor-pointer"
                    onClick={() => handleSort("signaal")}
                  >
                    Signaal{" "}
                    {sortField === "signaal" &&
                      (sortOrder === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    scope="col"
                    className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8 cursor-pointer"
                    onClick={() => handleSort("startdatum")}
                  >
                    Startdatum{" "}
                    {sortField === "startdatum" &&
                      (sortOrder === "asc" ? "▲" : "▼")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {currentTalenten.map((talent) => (
                  <tr
                    key={talent.id}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => onTalentClick(talent.id)}
                  >
                    <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                      <div className="flex items-center gap-x-4">
                        <img
                          alt=""
                          src={talent.foto}
                          className="h-12 w-12 rounded-full bg-gray-800"
                        />
                        <div>
                          <div className="truncate text-m font-medium max-w-36">
                            {talent.volledige_naam}
                          </div>
                          <div className="truncate text-xs font-regular">
                            {talent.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                      <div className="flex gap-x-3">
                        <div>
                          <div className="text-sm">
                            {talent.traject?.naam}
                          </div>
                          <div className="truncate text-xs font-regular text-gray-400">
                            [casemanager]
                          </div>
                        </div>
                        <div className="rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-white/10 self-center">
                          {talent.traject?.gemeente}
                        </div>
                      </div>
                    </td>
                    <td className="py-4 pl-0 pr-4 text-sm sm:pr-8 lg:pr-20">
                      <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                        {talent.lopend_traject?.status}
                      </div>
                    </td>
                    <td className="hidden py-4 pl-0 pr-8 text-sm md:table-cell lg:pr-20">
                      {getProgressItems(
                        talent?.traject,
                        talent?.lopend_traject,
                        true
                      )[0]
                        ? getProgressItems(
                            talent?.traject,
                            talent?.lopend_traject,
                            true
                          )[0]?.date.toLocaleDateString()
                        : "Geen"}
                    </td>
                    <td className="hidden py-4 pl-0 pr-4 text-right text-sm sm:table-cell sm:pr-6 lg:pr-8">
                      {talent.lopend_traject?.begindatum?.toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Paginering Controls */}
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-4">
              <div className="-mt-px flex w-0 flex-1">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:text-gray-300 disabled:cursor-not-allowed"
                >
                  <ArrowLongLeftIcon
                    aria-hidden="true"
                    className="mr-3 h-5 w-5 text-gray-400"
                  />
                  Vorige
                </button>
              </div>
              <div className="hidden md:-mt-px md:flex">
                {startPage > 1 && (
                  <>
                    <button
                      onClick={() => handlePageChange(1)}
                      className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      1
                    </button>
                    {startPage > 2 && (
                      <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                        ...
                      </span>
                    )}
                  </>
                )}
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    onClick={() => handlePageChange(number)}
                    aria-current={currentPage === number ? "page" : undefined}
                    className={`inline-flex items-center border-t-2 ${
                      currentPage === number
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    } px-4 pt-4 text-sm font-medium`}
                  >
                    {number}
                  </button>
                ))}
                {endPage < totalPages && (
                  <>
                    {endPage < totalPages - 1 && (
                      <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                        ...
                      </span>
                    )}
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      {totalPages}
                    </button>
                  </>
                )}
              </div>
              <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:text-gray-300 disabled:cursor-not-allowed"
                >
                  Volgende
                  <ArrowLongRightIcon
                    aria-hidden="true"
                    className="ml-3 h-5 w-5 text-gray-400"
                  />
                </button>
              </div>
            </nav>
          </>
        )}
      </div>
    </Layout>
  );
}