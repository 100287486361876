import { and, collection, doc, DocumentReference, getDoc, getDocs, limit,or,orderBy, query, QueryConstraint, QueryDocumentSnapshot, setDoc, SnapshotOptions, startAfter, startAt, updateDoc, where } from "firebase/firestore";
import { db } from "../utils/firebase";

export type RemoteUser = {
    ref: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    geboortedatum: string,
    data_eigenaar_ref?: DocumentReference
}

export type RemoteModeTalent = {
    aanmaakdatum: Date,
    score: string,
    talent_ref: DocumentReference, 
}

const userConverter = {
    toFirestore: (user: RemoteUser) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteUser {
            const data = snapshot.data(options)!;
            return {
                ref: snapshot.ref,
                achternaam: data.achternaam,
                email: data.email,
                gebruikers_rol: data.gebruikers_rol,
                geslacht: data.geslacht,
                profiel_foto: data.profiel_foto,
                telefoon_nummer: data.telefoon_nummer,
                voornaam: data.voornaam,
                geboortedatum: data.geboortedatum,
                data_eigenaar_ref: data.data_eigenaar_ref
            }
        }
  }

export const getAllUsersByCursor = async (maxPerPage: number, qCollection: QueryConstraint[] = [], dataOwner: DocumentReference, docRef?: DocumentReference) => {
    if (docRef) {
        const docSnap = await getDoc(doc(docRef, "user"));
        qCollection.push(startAfter(docSnap))
    }
    qCollection.unshift(where("data_eigenaar_ref", "==", dataOwner))
    qCollection.push(limit(maxPerPage))
    var q = query(collection(db, "user"), ...qCollection);

    const querySnapshot = await getDocs(q);
    const list: RemoteUser[] = []
    querySnapshot.forEach((doc) => {
        list.push({
            ref: doc.ref,
            achternaam: doc.data().achternaam,
            email: doc.data().email,
            gebruikers_rol: doc.data().gebruikers_rol,
            geslacht: doc.data().geslacht,
            profiel_foto: doc.data().profiel_foto,
            telefoon_nummer: doc.data().telefoon_nummer,
            voornaam: doc.data().voornaam,
            geboortedatum: doc.data().geboortedatum
        })
    });

    return list;
}

export default getAllUsersByCursor

// data must include only the changes
export const updateUserByRef = async (userRef: DocumentReference, data: any) => {
    return updateDoc(userRef, data)
}

export const getVerjaardagen = async () => {
    const currentDate = new Date();
    const verjaardag = query(collection(db, 'user'), where('geboortedatum', '>', currentDate));

    const querySnapshot = await getDocs(verjaardag);
    const list: RemoteUser[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push({
            ref: doc.ref,
            achternaam: doc.data().achternaam,
            email: doc.data().email,
            gebruikers_rol: doc.data().gebruikers_rol,
            geslacht: doc.data().geslacht,
            profiel_foto: doc.data().profiel_foto,
            telefoon_nummer: doc.data().telefoon_nummer,
            voornaam: doc.data().voornaam,
            geboortedatum: doc.data().geboortedatum
        })
    });

    return list; 
}

export const searchUserByName = async (dataOwner: DocumentReference, term: string) => {
    return await getAllUsersByCursor(5, [orderBy("voornaam"), where("voornaam", ">=", term)], dataOwner)
}

export const getUserDocumentById = async (id: string) => {
    const reference = await doc(db, "user", id).withConverter(userConverter)
    const docSnap = await getDoc(reference);
    
    if (!docSnap.exists()) {
        return null; 
    }

    return docSnap.data()
}

export const getUserByReference = async (reference: DocumentReference) => {
    const docSnap = await getDoc(reference);
    
    if (!docSnap.exists()) {
        return null; 
    }

    const user = docSnap.data();

    return {
    ref: docSnap.ref,
        achternaam: user.achternaam,
        data_eigenaar_ref: user.data_eigenaar_ref,
        email: user.email,
        gebruikers_rol: user.gebruikers_rol,
        geslacht: user.geslacht,
        profiel_foto: user.profiel_foto,
        telefoon_nummer: user.telefoon_nummer,
        talent: user.talent,
        voornaam: user.voornaam,
        geboortedatum: user.geboortedatum
    } as RemoteUser
}

export const getAllConsulentenDocuments = async (dataOwner: DocumentReference) => {
    const consulenten = query(collection(db, 'user'), and(where("data_eigenaar_ref", "==", dataOwner), or(where('gebruikers_rol', '==', "consulent"), where('gebruikers_rol', '==', "gemeente")))).withConverter(userConverter);

    const querySnapshot = await getDocs(consulenten);
    const list: RemoteUser[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list

}