import { collection, query, getDocs, getDoc, where, limit, DocumentReference, setDoc, doc, QueryDocumentSnapshot, SnapshotOptions, addDoc, orderBy, updateDoc, and } from "firebase/firestore";
import { db } from "../utils/firebase";
import { IntakeModel, NewIntakeModel } from "../domain/intake-utils";

export type RemoteIntake = {
    reference: DocumentReference,
    gemeente: string,
    traject_reference?: DocumentReference,
    start_datum?: Date,
    datum_uitgevoerd?: Date,
    consulent_reference?: DocumentReference,
    eerste_indruk_talent: string,
    documenten: string[],
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    korte_situatieschets: string,
    werkervaring: string,
    soort_uitkering: string,
    vervoer: string,
    taalniveau: string,
    gezondheid: string,
    opleiding: string,
    medicijnen: string,
    ingeburgerd: boolean,
    allergien: string,
    fysieke_belasting: string,
    diplomas: string,
    diplomas_gewaardeerd: boolean,
    kinderopvang: boolean,
    rijbewijs: boolean,
    talent_ervaring: string,
    talent_wensen: string,
    talent_kunnen: string,
    voorstel: string,
    aangemaakt_door_reference: DocumentReference,
};

const intakeConverter = {
    toFirestore: (intake: RemoteIntake) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteIntake {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            gemeente: data.gemeente,
            traject_reference: data.traject_reference,
            start_datum: data.start_datum?.toDate(),
            datum_uitgevoerd: data.datum_uitgevoerd?.toDate(),
            consulent_reference: data.consulent_reference,
            eerste_indruk_talent: data.eerste_indruk_talent,
            documenten: data.documenten,
            voornaam: data.voornaam,
            achternaam: data.achternaam,
            geboortedatum: data.geboortedatum?.toDate(),
            geslacht: data.geslacht,
            email: data.email,
            telefoon: data.telefoon,
            nationaliteit: data.nationaliteit,
            afkomst: data.afkomst,
            straat: data.straat,
            huisnummer: data.huisnummer,
            postcode: data.postcode,
            woonplaats: data.woonplaats,
            korte_situatieschets: data.korte_situatieschets,
            werkervaring: data.werkervaring,
            soort_uitkering: data.soort_uitkering,
            vervoer: data.vervoer,
            taalniveau: data.taalniveau,
            gezondheid: data.gezondheid,
            opleiding: data.opleiding,
            medicijnen: data.medicijnen,
            ingeburgerd: data.ingeburgerd,
            allergien: data.allergien,
            fysieke_belasting: data.fysieke_belasting,
            diplomas: data.diplomas,
            diplomas_gewaardeerd: data.diplomas_gewaardeerd,
            kinderopvang: data.kinderopvang,
            rijbewijs: data.rijbewijs,
            talent_ervaring: data.talent_ervaring,
            talent_wensen: data.talent_wensen,
            talent_kunnen: data.talent_kunnen,
            voorstel: data.voorstel,
            aangemaakt_door_reference: data.aangemaakt_door_reference
        }
    }
}

export default async (dataOwner: DocumentReference, max: number) => {
    const q = query(collection(db, "intake"), and(where("data_eigenaar_ref", "==", dataOwner), where("afgemeld_op", "==", null)), orderBy("datum_uitgevoerd", "desc"), limit(max)).withConverter(intakeConverter);
    const querySnapshot = await getDocs(q);
    const list: RemoteIntake[] = [];

    querySnapshot.forEach((doc) => {
        list.push(doc.data());
    });

    return list;
}

export const getIntakeDocumentById = async (id: string) => {
    const _doc = doc(db, "intake", id).withConverter(intakeConverter);
    const docSnap = await getDoc(_doc);

    if (docSnap.exists()) {
        return docSnap.data()
    }

    // const intake = await getIntakeById(id);
    // if (!intake) return Promise.reject("Geen intake gevonden");

    // let IntakeModel: any = {
    //     id: intake.reference.id,
    //     aanmaakdatum: intake.datum_uitgevoerd,            
    // };


    // if (options?.traject) {
    //     let traject = await getTrajectByID(intake.traject_reference);
    //     IntakeModel.traject = {
    //         naam: traject?.naam
    //     };
    // } else {
    //     IntakeModel.traject_id = intake.traject_reference.id;
    // }

    // if (options?.consulent) {
    //     let consulent = await getUserById(intake.consulent_reference);
    //     IntakeModel.consulent = {
    //         naam: consulent ? `${consulent.voornaam} ${consulent.achternaam}` : "Onbekend"
    //     };
    // } else {
    //     IntakeModel.consulent_id = intake.consulent_reference.id;
    // }

    // return IntakeModel

}

export const getIntakeByRef = async (intakeRef: DocumentReference) => {
    const docSnap = await getDoc(intakeRef.withConverter(intakeConverter));

    if (docSnap.exists()) {
        return docSnap.data()
    }
    
    return null
}
export const createIntake = async (model: NewIntakeModel, traject: DocumentReference, consulent: DocumentReference, documenten: string[]) => {
    await addDoc(collection(db, "intake"), {
            gemeente: model.gemeente,
            traject_reference: traject,
            start_datum: model.startDatum,
            datum_uitgevoerd: new Date(),
            consulent_reference: consulent,
            eerste_indruk_talent: model.eersteIndrukTalent,
            documenten: documenten,
            voornaam: model.voornaam,
            achternaam: model.achternaam,
            geboortedatum: model.geboortedatum,
            geslacht: model.gender,
            email: model.email,
            telefoon: model.telefoon,
            nationaliteit: model.nationaliteit,
            afkomst: model.afkomst,
            straat: model.straat,
            huisnummer: model.huisnummer,
            postcode: model.postcode,
            woonplaats: model.woonplaats,
            korte_situatieschets: model.situatieSchets,
            werkervaring: model.werkervaring,
            soort_uitkering: model.soortUitkering,
            vervoer: model.vervoer,
            taalniveau: model.taalniveau,
            gezondheid: model.gezondheid,
            opleiding: model.opleiding,
            medicijnen: model.medicijnen,
            ingeburgerd: model.ingeburgerd,
            allergien: model.allergien,
            fysieke_belasting: model.fysiekeBelasting,
            diplomas: model.diplomas,
            diplomas_gewaardeerd: model.diplomasGewaardeerd,
            kinderopvang: model.kinderopvang,
            rijbewijs: model.rijbewijs,
            talent_ervaring: model.ervaringTalent,
            talent_wensen: model.ambitieTalent,
            talent_kunnen: model.kunnenTalent,
            voorstel: model.voorstel,
            data_eigenaar_ref: model.data_eigenaar_ref,
            aangemaakt_door_reference: model.aangemaakt_door_reference,
            afgemeld_op: null
    });


}

export const afmeldenIntakeDocument = (id: string) => {
    return updateDoc(doc(db, "intake", id), {afgemeld_op: new Date()})
}
