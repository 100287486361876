import { Button } from "@headlessui/react";

export const AfmeldButton = ({ ...attributes }) => {

    return <Button
        {...attributes}
        className="hidden rounded-md bg-red px-2.5 py-1.5 text-sm font-semibold text-red-600 hover:bg-red-50 sm:block"
    >
        Afmelden
    </Button>

}