import {
    BellIcon,
    EllipsisVerticalIcon,
    NewspaperIcon,
    PhoneIcon,
  } from '@heroicons/react/24/outline';
  import Layout from '../../../layout';
  import Trajectprogress from '../../../components/traject/trajectprogress/Trajectprogress';
  import { getDocumentenByTalentId,
    getTalentById,
    getTalentByIdWithAdditionalInfo,
    TalentModel,
  } from '../../../domain/talent-utils';
  import {
    getLopendTrajectById,
    LopendTrajectModel,
  } from '../../../domain/lopend-traject-utils';
  import { useEffect, useState } from 'react';
  import DefaultImage from '../../../components/defaultimage/defaultimage';
  import Acties from '../../../components/modal/acties/Acties';
  import Generate from '../../../components/modal/generate/Generate';
  import dayjs from 'dayjs';
  import { getGenerativeModel } from 'firebase/vertexai-preview';
  import {
    EvaluatieModel,
    getEvaluatiesByTalentId,
  } from '../../../domain/evaluatie-utils';
  import { getIntakeById } from '../../../domain/intake-utils';
  import {
    createRapportage,
    getRapportageByTalentRef,
    RapportageModel,
  } from '../../../domain/rapportage-utils';
  import { vertexAI } from '../../../utils/firebase';
  import { useAuth } from '../../../context/AuthContext';
  import OpleidenTab from '../../../components/opleiden/App';
  import RapportagesTab from '../../../components/rapportages/App';
  import TalentTab from '../../../components/talent/App';
  import TrajectTab from '../../../components/traject/App';
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
  import { useNavigate, useParams } from 'react-router-dom';
  import Tabbartalenten from '../../../components/tabbar/talentenhub/Tabbartalenten';
  
  const tabs = [
    { name: 'Talent', href: '#', current: true },
    { name: 'Traject', href: '#', current: false },
    { name: 'Opleiden', href: '/caseload/details', current: false },
    { name: 'Documenten', href: '/talentenhub/rapportage', current: false },
    { name: 'Rooster', href: '/rooster', current: false },
  ];
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  
  export default function Example() {
    const [selectedPerson, setSelectedPerson] = useState<TalentModel | null>(null);
    const [lopendTraject, setLopendTraject] = useState<LopendTrajectModel | null>(
      null
    );
    const [openNieuw, setOpenNieuw] = useState(false);
    const [open, setOpen] = useState(false);
    const [loadingRapportage, setLoadingRapportage] = useState(false);
    const [tabContent, setTabContent] = useState(0);
    const [documenten, setDocumenten] = useState<{naam: string, aanmaak_datum: Date, id: string, soort: string}[]>([]);
    const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);
    const [isLoading, setIsLoading] = useState(true); // Nieuwe state voor de skeleton loader
  
    const navigate = useNavigate()

    const { id } = useParams();
  
    const { user } = useAuth();
  
    useEffect(() => {
      setIsLoading(true); // Begin met laden
      getTalentById(id, {traject: true}).then((talent) => {
        Promise.all([getEvaluatiesByTalentId(talent.id, user!), getLopendTrajectById(talent.lopend_traject_id, {
          consulent: true,
          werkbedrijf: true,
        }), getDocumentenByTalentId(user!, talent, talent.id)]).then(([evaluaties, lopendTraject, documenten]) => {
          setSelectedPerson(talent);
          setLopendTraject(lopendTraject);
            setEvaluaties(evaluaties)
            setDocumenten(documenten)
          setIsLoading(false); // Laden voltooid
        });
      });
    }, [id]);
  
    function generateRapportage() {
        setLoadingRapportage(true)
        Promise.all([getEvaluatiesByTalentId(selectedPerson!.id, user!), getIntakeById(selectedPerson!.intake_id)]).then(([evaluaties, intake]) => {
            const prompt = `Schrijf een uitgebreid voortgangsrapport in HTML formatting met tailwindcss op basis van de informatie die voor hand is van minimaal 400 en maximaal 700 woorden voor het Talent in kwestie. Het rapport moet een 360-graden beeld geven van de prestaties van het Talent. Verwerk in de tekst een zelfreflectie-instrument zoals het Dariuz model om de persoonlijke evaluatie van het Talent vast te leggen. Geef mogelijke aanbevelingen voor persoonlijke ontwikkeling, gebaseerd op de verzamelde data. Structureer het rapport als volgt: Begin met een inleiding, refereer hier naar het begin tot nu toe en opvallende punten in de rapportage, maak een uitgeschreven zelfreflectie middels het Dariuz model zonder bullets, maak een uitgeschreven samenvatting van de wervloerfeedback tot nu toe  zonder bullets, schrijf een samenvatting over de mogelijk behaalde competenties en sluit af met een totaal samenvatting en een evetuele conclusie. Zorg dat het doorlopende teksten zijn zonder bullets.`
            const data = "\n Op basis van de volgende informatie: evaluaties: " + JSON.stringify(evaluaties) + " en de intake: " + JSON.stringify(intake) + " en tot slot de persoonsgegevens: " + JSON.stringify(selectedPerson)
            const finalPrompt = prompt + data
            const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

            model.generateContent(finalPrompt).then((result) => {
                const response = result.response;
                const text = response.text();
                createRapportage({
                    auteur_id: user!.ref.id,
                    bedrijf_id: lopendTraject!.werkbedrijf!.reference.id,
                    talent_id: selectedPerson!.ref.id,
                    traject_id: selectedPerson!.traject!.reference!.id,
                    leidinggevende_id: lopendTraject!.werkvloer_begeleider_ref!.id,
                    consulent_id: lopendTraject!.consulent!.ref.id,
                    content: text,
                    soort: `${selectedPerson?.voornaam}_1e_Rapportage_${dayjs().format("DD-MM-YYYY-HH:mm")}`,
                    startDatum: lopendTraject!.begindatum ?? new Date(),
                    data_eigenaar: user?.data_eigenaar_ref!
                }).then((rapportage) => {
                    navigate(`/talentenhub/rapportages/detail/${rapportage.id}`)
                })
            })
        })

    }

    function getRender(selectedPerson) {
      tabs.forEach((tab) => (tab.current = false));
      tabs[tabContent].current = true;
      switch (tabContent) {
        case 0:
          if (!selectedPerson) return null;
          return TalentTab(selectedPerson, lopendTraject);
        case 1:
          return TrajectTab(evaluaties);
        case 2:
          return OpleidenTab();
        case 3:
          return RapportagesTab(navigate, documenten);
      }
    }
  
    return (
      <>
        <Layout>
        <Tabbartalenten />
          {isLoading ? (
            // Skeleton Loader
            <div className="flex justify-center items-center h-screen">
              <div className="animate-pulse space-y-4">
                <div className="h-10 w-96 bg-gray-200 rounded"></div>
                <div className="h-6 w-64 bg-gray-200 rounded"></div>
                <div className="h-80 w-full bg-gray-200 rounded"></div>
              </div>
            </div>
          ) : (
            <div className="flex min-h-full flex-col bg-amber-50/50 rounded-lg">
              {/* 3 column wrapper */}
              <div className="mx-auto w-full max-w-7xl grow lg:flex">
                {/* Left sidebar & main wrapper */}
                <div className="flex-1 lg:flex">
                  <div className="px-0 py-0 md:py-6 sm:px-0 lg:pl-8 lg:flex-1 xl:pl-6">
                    <div className="mx-auto  px-0 sm:px-6 lg:px-8 ">
                      <div className="pb-6">
                        <div className="h-24 bg-sky-400 sm:h-20 lg:h-28" />
                        <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                          <div>
                            <div className="-m-1 flex">
                              <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                <img
                                  alt=""
                                  src={selectedPerson?.foto}
                                  className="size-24 shrink-0 sm:size-40 lg:size-48"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 sm:ml-6 sm:flex-1">
                            <div>
                              <div className="flex items-center">
                                <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                  {selectedPerson?.voornaam}{' '}
                                  {selectedPerson?.achternaam}
                                </h3>
                                <span className="ml-2.5 inline-block size-2 shrink-0 rounded-full bg-green-400">
                                  <span className="sr-only">Online</span>
                                </span>
                              </div>
                              <p className="text-sm text-gray-500">
                                {selectedPerson?.email}
                              </p>
                            </div>
                            <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                              <button
                                type="button"
                                onClick={(e) => setOpenNieuw(true)}
                                className="inline-flex w-full shrink-0 items-center justify-center rounded-md bg-gradient-to-r from-pink-500 to-violet-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1 "
                              >
                                <span>Document Genereren</span>
                                <Acties
                                  open={openNieuw}
                                  setOpen={setOpenNieuw}
                                  onclick={generateRapportage}
                                />
                              </button>
  
                              <button
                                type="button"
                                className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                <a href={`tel:${selectedPerson?.telefoonnummer}`}>
                                  <span>Bellen</span>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div className="px-6 ">
                      <div className="mt-4 ">
                        <div className="hidden sm:block">
                          <nav className="-mb-px flex space-x-8">
                            {tabs.map((tab, i) => (
                              <a
                                key={tab.name}
                                onClick={(e) => {
                                  setTabContent(i);
                                }}
                                aria-current={tab.current ? 'page' : undefined}
                                className={classNames(
                                  tab.current
                                    ? 'border-sky-400 text-sky-400'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                                )}
                              >
                                {tab.name}
                              </a>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>
  
                    <div className="">
                      {getRender(selectedPerson)}
                    </div>
                  </div>
  
                  <div className="shrink-0 border-gray-200 px-4 py-6 sm:px-6 lg:w-96 lg:border-l lg:border-t-0 lg:pr-8 xl:pr-6 h-max">
                    <Trajectprogress
                      traject={selectedPerson?.traject}
                      lopendTraject={lopendTraject}
                    />
                  </div>
                </div>
              </div>
              {loadingRapportage && <Generate/>}
            </div>
          )}
        </Layout>
      </>
    );
  }