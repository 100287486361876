import { debug } from 'webpack';
import { getBedrijfByReference, BedrijfModel } from './bedrijf-utils';
import { DocumentReference } from 'firebase/firestore';
import { getLopendeTrajectenDocuments, getLopendTrajectDocumentById, getLopendTrajectDocumentByReference } from '../datasource/lopend-traject-utils';
import { getUserById, UserModel } from './user-utils';

export type LopendTrajectModel = {
    id?: string;
    begindatum?: Date,
    consulent_id?: string,
    consulent?: UserModel | null,
    consulent_ref?: DocumentReference,
    status?: string,
    talent_id?: string,
    talent_ref?: DocumentReference,
    traject_ref?: DocumentReference,
    verwachte_einddatum?: Date,
    werkbedrijf_id?: string,
    werkbedrijf?: BedrijfModel | null,
    werkdagen?: string,
    werkvloer_begeleider_ref?: DocumentReference
};

export const getLopendeTrajecten = async (max: number, user: UserModel) => {
    const list: LopendTrajectModel[] = []

    var LopendTrajecten = await getLopendeTrajectenDocuments(max, user?.data_eigenaar_ref!)
    LopendTrajecten.forEach((LopendTraject) => {
        list.push({
            id: LopendTraject.reference.id,
            begindatum: LopendTraject.begindatum,
            consulent_ref: LopendTraject.consulent_ref,
            status: LopendTraject.status,
            talent_ref: LopendTraject.talent_ref,
            traject_ref: LopendTraject.traject_ref,
            verwachte_einddatum: LopendTraject.verwachte_einddatum,
            werkdagen: LopendTraject.werkdagen,
            werkvloer_begeleider_ref: LopendTraject.werkvloer_begeleider_ref,

        })
    })

    return list;
}

export const getLopendTrajectByReference = async (reference?: DocumentReference) => {

    if (!reference) return null

    const lopendTraject = await getLopendTrajectDocumentByReference(reference)

    if (!lopendTraject) return null

    const consulent = await getUserById(lopendTraject.consulent_ref)
    const werkbedrijf = await getBedrijfByReference(lopendTraject.werkbedrijf_ref)

    return {
        id: lopendTraject.reference.id,
            begindatum: lopendTraject.begindatum,
            consulent: consulent,
            status: lopendTraject.status,
            talent_ref: lopendTraject.talent_ref,
            traject_ref: lopendTraject.traject_ref,
            verwachte_einddatum: lopendTraject.verwachte_einddatum,
            werkbedrijf: werkbedrijf,
            werkdagen: lopendTraject.werkdagen,
            werkvloer_begeleider_ref: lopendTraject.werkvloer_begeleider_ref,
    } as LopendTrajectModel
 
};

export const getLopendTrajectById = async (id?: string, options?: {consulent?: boolean, werkbedrijf?: boolean}) => {

    if (!id) return null

    const lopendTraject = await getLopendTrajectDocumentById(id)

    if (!lopendTraject) return null

    const tempLopendTraject: LopendTrajectModel = {
        id: lopendTraject.reference.id,
            begindatum: lopendTraject.begindatum,
            status: lopendTraject.status,
            talent_ref: lopendTraject.talent_ref,
            traject_ref: lopendTraject.traject_ref,
            verwachte_einddatum: lopendTraject.verwachte_einddatum,
            werkdagen: lopendTraject.werkdagen,
            werkvloer_begeleider_ref: lopendTraject.werkvloer_begeleider_ref,
    }

    if (options?.consulent) {
        let consulent = await getUserById(lopendTraject.consulent_ref)
        tempLopendTraject.consulent = consulent
    } else {
        tempLopendTraject.consulent_id = lopendTraject.consulent_ref.id
    }
  
    if (options?.werkbedrijf) {
      let werkbedrijf = await getBedrijfByReference(lopendTraject.werkbedrijf_ref)
      tempLopendTraject.werkbedrijf = werkbedrijf
  } else {
    tempLopendTraject.werkbedrijf_id = lopendTraject.werkbedrijf_ref.id
  }

    return tempLopendTraject
 
};

export const getWerkbedrijfLocatie = async (lopendTraject: LopendTrajectModel) => {
    // if (lopendTraject.werkbedrijf_ref) {
    //     const bedrijf = await getBedrijfByReference(lopendTraject.werkbedrijf_ref);

    //     if (bedrijf) {
    //         return {
    //             naam: bedrijf.naam,
    //             postcode: bedrijf.postcode,
    //             straat: bedrijf.straat,
    //             plaats: bedrijf.plaats,
    //             locatie: `${bedrijf.naam}, ${bedrijf.straat} ${bedrijf.huisnummer}, ${bedrijf.postcode} ${bedrijf.plaats}`,
    //         };
    //     }
    // }
    // return null; 
};