'use client'
import Layout from '../../../layout'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Dialog, DialogPanel, Field, Label, Switch } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import Beheer from '../../../components/subnav/beheer/Beheer'
import getAllUsersByCursor from '../../../datasource/user-utils'
import { UserModel } from '../../../domain/user-utils'
import { useAuth } from '../../../context/AuthContext'

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'Invoices', href: '#' },
  { name: 'Clients', href: '#' },
  { name: 'Expenses', href: '#' },
]
const secondaryNavigation = [
  { name: 'General', href: '#', icon: UserCircleIcon, current: true },
  { name: 'Security', href: '#', icon: FingerPrintIcon, current: false },
  { name: 'Notifications', href: '#', icon: BellIcon, current: false },
  { name: 'Plan', href: '#', icon: CubeIcon, current: false },
  { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
  { name: 'Team members', href: '#', icon: UsersIcon, current: false },
]

type Person = {
  name: string,
  title: string,
  email: string,
  role: string
}


const people: Person[] = [
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
  },
  // More people...
]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Settings() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState<UserModel[]>([])
  const [users, setUsers] = useState<UserModel[]>([])

  const {user} = useAuth()

  // useLayoutEffect(() => {
  //   const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < people.length
  //   setChecked(selectedPeople.length === people.length)
  //   setIndeterminate(isIndeterminate)
  // }, [selectedPeople])

  useEffect(() => {

    getAllUsersByCursor(250, [], user?.data_eigenaar_ref!).then((response) => {
      setUsers(response)
    })

  }, [])

  function toggleAll() {
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  return (
    <Layout>
      <>
        <div className="mx-auto lg:flex lg:gap-x-16 lg:px-8">
          <h1 className="sr-only">General Settings</h1>

          <Beheer />

          <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20 overflow-auto">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Gebruikers</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  This information will be displayed publicly so be careful what you share.
                </p>

                <div className="">
                  <div className="sm:flex sm:items-center">

                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="relative">
                          {selectedPeople.length > 0 && (
                            <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                              <button
                                type="button"
                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                              >
                                Bulk edit
                              </button>
                              <button
                                type="button"
                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                              >
                                Delete all
                              </button>
                            </div>
                          )}
                          <table className="min-w-full table-fixed divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    checked={checked}
                                    onChange={toggleAll}
                                  />
                                </th>
                                <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sticky left-10 bg-white">
                                  Voornaam
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Achternaam
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Email
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Geboortedatum
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Geslacht
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Gemeente
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Huisnummer
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Woonplaats
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Postcode
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Straat
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Telefoonnummer
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Gebruikersrol
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {users.map((person) => (
                                <tr key={person.email} className={selectedPeople.includes(person) ? 'bg-gray-50' : undefined}>
                                  <td className="relative px-7 sm:w-12 sm:px-6">
                                    {selectedPeople.includes(person) && (
                                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                    )}
                                    <input
                                      type="checkbox"
                                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      value={person.email}
                                      checked={selectedPeople.includes(person)}
                                      onChange={(e) =>
                                        setSelectedPeople(
                                          e.target.checked
                                            ? [...selectedPeople, person]
                                            : selectedPeople.filter((p) => p !== person),
                                        )
                                      }
                                    />
                                  </td>
                                  
                                  <td className="whitespace-nowra py-4 text-sm text-gray-500 sticky left-8 bg-slate-100	backdrop-blur-sm	">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.voornaam}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.achternaam}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.email}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.geboortedatum}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.geslacht}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.talent?.gemeente}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.talent?.huisnummer}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.talent?.plaats}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.talent?.postcode}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.talent?.straat}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td> */}
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={person.telefoon_nummer}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div>
                                      <input
                                        id="email"
                                        name="email"
                                        type="text"
                                        defaultValue={person.gebruikers_rol}
                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.email}</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.gebruikers_rol}</td> */}
                                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                      Edit<span className="sr-only">, {person.voornaam}</span>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>





            </div>
          </main>
        </div>
      </>
    </Layout>
  )
}

