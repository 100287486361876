import Layout from './layoutmobile';
import { useEffect, useState } from 'react';
import { getTalents, TalentModel } from '../../domain/talent-utils';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { PlusIcon, ChartBarIcon, ClipboardIcon, UserGroupIcon } from '@heroicons/react/20/solid';

export default function Dashboard() {
  const [talenten, setTalenten] = useState<TalentModel[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    getTalents(100, user!).then((talenten) => setTalenten(talenten));
  }, [user]);

  return (
    <>
      <Layout>
        <div className="min-h-full bg-gray-50 pb-36 px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="pt-6 pb-4">
            <h1 className="text-2xl font-bold text-gray-900 tracking-tight">
              Welkom terug, {user?.voornaam}!
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              Hier is een overzicht van jouw talenten en trajecten.
            </p>
          </div>

          {/* Statistieken */}
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 mt-4">
            <div className="flex flex-col items-center bg-white rounded-xl shadow-md p-4 transition-all hover:shadow-lg">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-50">
                <UserGroupIcon className="h-6 w-6 text-blue-600" />
              </div>
              <p className="mt-2 text-sm text-gray-500">Aantal Talenten</p>
              <p className="text-xl font-semibold text-gray-900">{talenten.length}</p>
            </div>
            <div className="flex flex-col items-center bg-white rounded-xl shadow-md p-4 transition-all hover:shadow-lg">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-green-50">
                <ChartBarIcon className="h-6 w-6 text-green-600" />
              </div>
              <p className="mt-2 text-sm text-gray-500">Actieve Trajecten</p>
              <p className="text-xl font-semibold text-gray-900">25</p>
            </div>
            <div className="flex flex-col items-center bg-white rounded-xl shadow-md p-4 transition-all hover:shadow-lg">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-yellow-50">
                <ClipboardIcon className="h-6 w-6 text-yellow-600" />
              </div>
              <p className="mt-2 text-sm text-gray-500">Taken Vandaag</p>
              <p className="text-xl font-semibold text-gray-900">7</p>
            </div>
          </div>

          {/* Talenten Overzicht */}
          <div className="mt-10">
            <h2 className="text-lg font-medium text-gray-900">Mijn Talenten</h2>
            <ul role="list" className="mt-4 space-y-4">
              {talenten.map((talent) => (
                <li
                  key={talent.id}
                  className="flex items-center rounded-2xl bg-white p-4 shadow-md hover:shadow-lg transition-all"
                >
                  <img
                    alt={talent.volledige_naam}
                    src={talent?.foto || '/placeholder.jpg'}
                    className="h-14 w-14 rounded-full object-cover border border-gray-300"
                  />
                  <div className="ml-4 flex-1">
                    <h3 className="text-base font-semibold text-gray-900">
                      {talent.volledige_naam}
                    </h3>
                    <p className="text-sm text-gray-500">{talent.email}</p>
                  </div>
                  <Link
                    to={`/vandaag/detail/${talent.id}`}
                    className="rounded-full bg-blue-50 px-4 py-2 text-sm font-medium text-blue-600 shadow hover:bg-blue-100 transition"
                  >
                    Bekijk
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
}