import Layout from '../../layout'
import { Fragment, useEffect, useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Bijenkorf from '../../components/subnav/bijenkorf/bijenkorf'
import { getTalentenWithUitstroom, TalentModel } from '../../domain/talent-utils'
import { useAuth } from '../../context/AuthContext'
import { getProgressItems } from '../../utils/traject-progress-calculator'


const currencies = ['CAD', 'USD', 'AUD', 'EUR', 'GBP']
const navigation = {
  categories: [
    {
      name: 'Women',
      featured: [
        { name: 'Sleep', href: '#' },
        { name: 'Swimwear', href: '#' },
        { name: 'Underwear', href: '#' },
      ],
      collection: [
        { name: 'Everything', href: '#' },
        { name: 'Core', href: '#' },
        { name: 'New Arrivals', href: '#' },
        { name: 'Sale', href: '#' },
      ],
      categories: [
        { name: 'Basic Tees', href: '#' },
        { name: 'Artwork Tees', href: '#' },
        { name: 'Bottoms', href: '#' },
        { name: 'Underwear', href: '#' },
        { name: 'Accessories', href: '#' },
      ],
      brands: [
        { name: 'Full Nelson', href: '#' },
        { name: 'My Way', href: '#' },
        { name: 'Re-Arranged', href: '#' },
        { name: 'Counterfeit', href: '#' },
        { name: 'Significant Other', href: '#' },
      ],
    },
    {
      name: 'Men',
      featured: [
        { name: 'Casual', href: '#' },
        { name: 'Boxers', href: '#' },
        { name: 'Outdoor', href: '#' },
      ],
      collection: [
        { name: 'Everything', href: '#' },
        { name: 'Core', href: '#' },
        { name: 'New Arrivals', href: '#' },
        { name: 'Sale', href: '#' },
      ],
      categories: [
        { name: 'Artwork Tees', href: '#' },
        { name: 'Pants', href: '#' },
        { name: 'Accessories', href: '#' },
        { name: 'Boxers', href: '#' },
        { name: 'Basic Tees', href: '#' },
      ],
      brands: [
        { name: 'Significant Other', href: '#' },
        { name: 'My Way', href: '#' },
        { name: 'Counterfeit', href: '#' },
        { name: 'Re-Arranged', href: '#' },
        { name: 'Full Nelson', href: '#' },
      ],
    },
  ],
  pages: [
    { name: 'Company', href: '#' },
    { name: 'Stores', href: '#' },
  ],
}


const tabs = [
  { name: 'Hardlopers', href: '#', current: true },
  { name: 'Nieuw', href: '#', current: false },
  { name: 'Bijna klaar', href: '#', current: false },

]

//   function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
//   }



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pool() {

  const {user} = useAuth()
  const [open, setOpen] = useState(false)
  const [talenten, setTalenten] = useState<TalentModel[]>([])

  useEffect(() => {
    getTalentenWithUitstroom(user!, {lopendTraject: true, traject: true}).then((response) => {
      setTalenten(response)
    })
  }, [])

  return (
    <Layout>
      <Bijenkorf />

      <div className="bg-amber-50">
        {/* Mobile menu */}
        <Dialog open={open} onClose={setOpen} className="relative z-40 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative flex w-full max-w-xs transform flex-col bg-white pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <div className="flex px-4 pb-2 pt-5">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>

              {/* Links */}
              <TabGroup className="mt-2">
                <div className="border-b border-gray-200">
                  <TabList className="-mb-px flex space-x-8 px-4">
                    {navigation.categories.map((category) => (
                      <Tab
                        key={category.name}
                        className="flex-1 whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-base font-medium text-gray-900 data-[selected]:border-indigo-600 data-[selected]:text-indigo-600"
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </TabList>
                </div>
                <TabPanels as={Fragment}>
                  {navigation.categories.map((category, categoryIdx) => (
                    <TabPanel key={category.name} className="space-y-12 px-4 pb-6 pt-10">
                      <div className="grid grid-cols-1 items-start gap-x-6 gap-y-10">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10">
                          <div>
                            <p id={`mobile-featured-heading-${categoryIdx}`} className="font-medium text-gray-900">
                              Featured
                            </p>
                            <ul
                              role="list"
                              aria-labelledby={`mobile-featured-heading-${categoryIdx}`}
                              className="mt-6 space-y-6"
                            >
                              {category.featured.map((item) => (
                                <li key={item.name} className="flex">
                                  <a href={item.href} className="text-gray-500">
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div>
                            <p id="mobile-categories-heading" className="font-medium text-gray-900">
                              Categories
                            </p>
                            <ul role="list" aria-labelledby="mobile-categories-heading" className="mt-6 space-y-6">
                              {category.categories.map((item) => (
                                <li key={item.name} className="flex">
                                  <a href={item.href} className="text-gray-500">
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10">
                          <div>
                            <p id="mobile-collection-heading" className="font-medium text-gray-900">
                              Collection
                            </p>
                            <ul role="list" aria-labelledby="mobile-collection-heading" className="mt-6 space-y-6">
                              {category.collection.map((item) => (
                                <li key={item.name} className="flex">
                                  <a href={item.href} className="text-gray-500">
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>

                          <div>
                            <p id="mobile-brand-heading" className="font-medium text-gray-900">
                              Brands
                            </p>
                            <ul role="list" aria-labelledby="mobile-brand-heading" className="mt-6 space-y-6">
                              {category.brands.map((item) => (
                                <li key={item.name} className="flex">
                                  <a href={item.href} className="text-gray-500">
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  ))}
                </TabPanels>
              </TabGroup>

              <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                {navigation.pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <a href={page.href} className="-m-2 block p-2 font-medium text-gray-900">
                      {page.name}
                    </a>
                  </div>
                ))}
              </div>

              <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                <div className="flow-root">
                  <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                    Create an account
                  </a>
                </div>
                <div className="flow-root">
                  <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                    Sign in
                  </a>
                </div>
              </div>

              <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                {/* Currency selector */}
                <form>
                  <div className="inline-block">
                    <label htmlFor="mobile-currency" className="sr-only">
                      Currency
                    </label>
                    <div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
                      <select
                        id="mobile-currency"
                        name="currency"
                        className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800"
                      >
                        {currencies.map((currency) => (
                          <option key={currency}>{currency}</option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </Dialog>



        <main className="mx-auto max-w-2xl pb-24 pt-8 sm:px-6 sm:pt-16 lg:max-w-7xl lg:px-8 ">
          <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
            <div className="flex sm:items-baseline sm:space-x-4">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">De bezige bijtjes</h1>

            </div>
            <p className="text-sm text-gray-600">
              Nieuwe bijtjes on the block{' '}
              <time dateTime="2021-03-22" className="font-medium text-gray-900">
                22 November, 2023
              </time>
            </p>
            <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 sm:hidden">
              View invoice
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>

          {/* Products */}
          <section aria-labelledby="products-heading" className="mt-6">

            <div className="space-y-8">
              {talenten.map((talent) => (
                <div
                  key={talent.id}
                  className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                >

                  <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                    <div className="sm:flex lg:col-span-7">
                      <div className="blur-lg aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                        <img
                          alt=""
                          src={talent.foto}
                          className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                        />
                      </div>

                      <div className="mt-6 sm:ml-6 sm:mt-0">
                        <h3 className="text-2xl font-medium text-gray-900 blur-sm">
                          <a>{talent.volledige_naam}</a>
                        </h3>
                        <p className="mt-2 text-sm font-medium text-gray-900 ">{talent.gemeente}</p>
                        <p className="mt-3 text-sm text-gray-500"></p>
                        <div className=" mt-12 shrink-0">
                          <button
                            type="button"
                            className="relative rounded-md bg-slate-200 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Profiel opvragen
                          </button>
                        </div>
                      </div>

                    </div>

                    <div className="mt-6 lg:col-span-5 lg:mt-0">
                      <dl className="grid grid-cols-2 gap-x-6 text-sm">
                        <div>
                          <dt className="font-medium text-gray-900">Traject gegevens</dt>
                          <dd className="mt-3 text-gray-500">
                            <span className="block">{talent.traject?.naam}</span>
                          </dd>
                        </div>
                        <div>
                          <dt className="font-medium text-gray-900">Talent details</dt>
                          <dd className="mt-3 space-y-3 text-gray-500 blur-sm">
                            <p>{talent.email}</p>
                            <p>{talent.telefoonnummer}</p>

                          </dd>

                        </div>
                      </dl>
                    </div>
                  </div>

                  <div className="flex items-center gap-x-6 bg-yellow-500 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                    <p className="text-sm/6 text-white">
                      <a href="#">
                        <strong className="font-semibold">Facilitair</strong>
                        <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                          <circle r={1} cx={1} cy={1} />
                        </svg>
                        Opleiding Facilitair medewerker<span aria-hidden="true">&rarr;</span>
                      </a>
                    </p>
                    <div className="flex flex-1 justify-end">
                      
                    </div>
                  </div>

                  <div className="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
                    <h4 className="sr-only">Status</h4>
                    <p className="text-sm font-medium text-gray-900">
                    </p>
                    <div aria-hidden="true" className="mt-6">
                      <div className="overflow-hidden rounded-full bg-gray-200">
                        <div
                          style={{ width: `calc((1 * ${getProgressItems(talent.traject, talent.lopend_traject).filter((it) => ["Start traject", "Ontplooiing", "Praktijkverklaring", "1e rapportage"].includes(it!.name) && it!.status == "complete").length}) / 4 * 100%)` }}
                          className="h-2 rounded-full bg-sky-600 animate-pulse"
                        />
                      </div>
                      <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-sky-400 sm:grid ">

                        {getProgressItems(talent.traject, talent.lopend_traject).filter((it) => ["Start traject", "Ontplooiing", "Praktijkverklaring", "1e rapportage"].includes(it!.name)).map((item) => {
                          return <div className={classNames(item?.status == "complete" ? 'text-sky-400' : 'text-gray-400', 'text-center')}>{item?.name}</div>
                        })}

                        {/* <div className="text-sky-400">Start traject</div>
                        <div className={classNames(2 > 0 ? 'text-sky-400' : '', 'text-center')}>
                          Eerste rapportages
                        </div>
                        <div className={classNames(3 > 1 ? 'text-sky-200' : '', 'text-center')}>
                          Praktijkverklaring
                        </div>
                        <div className={classNames(3 > 2 ? 'text-sky-200' : '', 'text-right')}>
                          Ontplooiing
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>


        </main>


      </div>
    </Layout>
  )
}