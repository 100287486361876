import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SignaalModel, getRapportageSignalen } from "../../../domain/signalen-utils";
import DefaultImage from "../../defaultimage/defaultimage";

export default function Example({ user, infinite }) {
  const [signalen, setSignalen] = useState<SignaalModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getRapportageSignalen(user!, infinite ? 999 : 7).then((response) => {
      setSignalen(response);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="mt-6 overflow-y-auto" >
      {isLoading ? (
        // Skeleton Loader
        <ul role="list" className="divide-y divide-gray-100">
          {Array.from({ length: 6 }).map((_, index) => (
            <li
              key={index}
              className="py-4 flex items-center justify-between animate-pulse"
            >
              <div className="flex items-center">
                <div className="h-12 w-12 rounded-full bg-gray-200" />
                <div className="ml-4">
                  <div className="h-4 bg-gray-200 rounded w-32 mb-2"></div>
                  <div className="h-3 bg-gray-200 rounded w-24"></div>
                </div>
              </div>
              <div className="h-3 bg-gray-200 rounded w-16"></div>
            </li>
          ))}
        </ul>
      ) : (
        <ul role="list" className="divide-y divide-gray-100">
          {signalen.map((signaal, index) => (
            <li
              key={index}
              className="py-4 flex items-center justify-between"
            >
              <Link
                to={`/talentenhub/talentdetails/${signaal.talent.id}`}
                className="flex items-center"
              >
                <DefaultImage
                  profiel_foto={signaal.talent.profiel_foto}
                  className="h-12 w-12 rounded-full"
                />
                <div className="ml-4">
                  <p className="text-sm font-medium text-gray-900">
                    {signaal.talent.voornaam} {signaal.talent.achternaam}
                  </p>
                  <p className="text-xs text-gray-500">
                    {signaal.notificatieType}
                  </p>
                </div>
              </Link>
              <div className="text-xs text-gray-500">
                {signaal.datum?.toLocaleDateString()}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}