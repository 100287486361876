import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import Layout from '../../../layout'
import Talenten from '../../../components/subnav/talenten/talenten'
import { afmeldenIntake, getIntakes, IntakeModel } from '../../../domain/intake-utils'
import { useEffect, useState } from 'react'
import { Timestamp } from 'firebase/firestore';
import { useAuth } from '../../../context/AuthContext'
import { AfmeldButton } from '../../../components/button/afmeld-button'
import { Link } from 'react-router-dom'


const statuses = {
    Nieuw: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Intakes() {
    const [intakes, setIntakes] = useState<IntakeModel[]>([])
    const { user } = useAuth()
    useEffect(() => {

        getIntakes(5, user!, { consulent: true, traject: true, aangemaaktDoor: true }).then((response) => {

            return setIntakes(response)

        })

    }, [])

    const afmelden = (index) => {
        afmeldenIntake(intakes[Number(index)].id!).then((_) => {
            const arr = [...intakes]
            delete arr[Number(index)]
            setIntakes(arr)
        })
    }

    return (
        <Layout>

            <Talenten />
            <ul role="list" className="mx-auto max-w-7xl divide-y divide-gray-100 overflow-y-auto max-h-screen px-2">
                {intakes.map((intake, index) => (
                    <li key={intake.id} className="flex items-center justify-between gap-x-6 py-5">
                        <Link to={{ pathname: `/talentenhub/intakedocument/${intake.id}` }}>
                        <div className="min-w-0">
                            
                                <div className="flex items-start gap-x-3">
                                    {/* intake.naam van de talent, inplaats van voorstel. intake.data_eigenaar_ref */}
                                    <p className="text-sm font-semibold leading-6 text-gray-900">{intake?.voornaam ?? "Onbekend"} {intake?.achternaam ?? "Onbekend"}</p>
                                    <p
                                        className={classNames(
                                            // statuses[project.status],
                                            'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                        )}
                                    >
                                        {intake.traject?.naam}
                                    </p>
                                </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="whitespace-nowrap">
                                    Binnengekomen op {intake?.datumUitgevoerd?.toLocaleDateString('nl-NL')}
                                </p>
                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                    <circle r={1} cx={1} cy={1} />
                                </svg>
                                <p className="truncate">Door: {intake?.aangemaaktDoor?.naam}</p>

                            </div>
                        </div>
                        </Link>
                        <div className="flex flex-none items-center gap-x-4">
                            <a
                                href={"./intakedocument/" + intake.id}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Bekijk intake<span className="sr-only">, {intake.voorstel}</span>
                            </a>
                            <AfmeldButton onClick={(_) => afmelden(index)} />
                        </div>
                    </li>
                ))}
            </ul>``
        </Layout>
    )
}