import Layout from '../layoutmobile';
import Tabbarmobile from '../../../components/tabbar/folder/Tabbarmobile';
import Breadcrumbs from '../../../components/breadcrumb/breadcrumbs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTalentById, TalentModel } from '../../../domain/talent-utils';

export default function Example() {
  const { talentId } = useParams();

  const [suggestions, setSuggestions] = useState<{ id: number; text: string; completed: boolean }[]>([
    { id: 1, text: 'Vandaag gaan we focussen op communicatie.', completed: false },
    { id: 2, text: 'Blijf positief en werk aan je zelfvertrouwen.', completed: false },
    { id: 3, text: 'Stel een klein doel voor vandaag en werk eraan!', completed: false },
  ]);

  const [talent, setTalent] = useState<TalentModel>()

  useEffect(() => {
    getTalentById(talentId).then((talent) => {
      setTalent(talent)
    });
  }, [])

  const addSuggestion = () => {
    const newSuggestion = { id: Date.now(), text: 'Nieuwe suggestie toevoegen...', completed: false };
    setSuggestions([...suggestions, newSuggestion]);
  };

  const updateSuggestion = (id: number, value: string) => {
    setSuggestions(suggestions.map(suggestion => (suggestion.id === id ? { ...suggestion, text: value } : suggestion)));
  };

  const toggleCompletion = (id: number) => {
    setSuggestions(
      suggestions.map(suggestion =>
        suggestion.id === id ? { ...suggestion, completed: !suggestion.completed } : suggestion
      )
    );
  };

  const removeSuggestion = (id: number) => {
    setSuggestions(suggestions.filter(suggestion => suggestion.id !== id));
  };

  return (
    <>
      <Layout>
        <Breadcrumbs personName={talent?.volledige_naam ?? ""} />
        <div className="min-h-full pb-36">
          <div className="flex h-full flex-col overflow-y-scroll ">
            {/* Werkkaarten Sectie */}
            <div className="px-4 py-6">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-gray-900">Werkkaarten</h2>
                <button
                  type="button"
                  onClick={addSuggestion}
                  className="rounded-full bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow hover:bg-blue-700"
                >
                  + Toevoegen
                </button>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Voeg suggesties of aandachtspunten toe die vandaag relevant zijn.
              </p>
              <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                {suggestions
                  .filter(suggestion => !suggestion.completed)
                  .map(suggestion => (
                    <div
                      key={suggestion.id}
                      className="rounded-lg p-4 border border-gray-200 bg-white shadow hover:shadow-lg transition"
                    >
                      <textarea
                        value={suggestion.text}
                        onChange={(e) => updateSuggestion(suggestion.id, e.target.value)}
                        rows={2}
                        className="block w-full resize-none rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 sm:text-sm"
                      />
                      <div className="mt-3 flex items-center justify-between">
                        <button
                          type="button"
                          onClick={() => toggleCompletion(suggestion.id)}
                          className="text-sm text-green-600 hover:text-green-700"
                        >
                          Afgerond
                        </button>
                        <button
                          type="button"
                          onClick={() => removeSuggestion(suggestion.id)}
                          className="text-sm text-red-600 hover:text-red-700"
                        >
                          Verwijderen
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Header met uitleg */}
            <div className="px-4 py-5 bg-blue-100 border-b border-blue-200 shadow-sm">
              <h2 className="text-lg font-semibold text-blue-900">Doelen voor de cliënt</h2>
              <p className="mt-1 text-sm text-blue-800">
                Tips en hulpmiddelen om de cliënt te ondersteunen bij het behalen van zijn of haar doelen.
              </p>
            </div>

            {/* Tips en Tricks */}
            <div className="px-4 py-6">
              <h3 className="text-lg font-semibold text-gray-900">Tips en Tricks</h3>
              <ul className="mt-4 space-y-4">
                {[
                  {
                    title: 'Stel SMART-doelen',
                    description: 'Maak doelen Specifiek, Meetbaar, Acceptabel, Realistisch en Tijdsgebonden.',
                  },
                  {
                    title: 'Focus op kleine stappen',
                    description: 'Breek grote doelen op in kleinere stappen voor meer overzicht.',
                  },
                  {
                    title: 'Blijf motiveren',
                    description: 'Geef positieve feedback en vier behaalde mijlpalen.',
                  },
                ].map((tip, index) => (
                  <li key={index} className="bg-white border border-gray-200 rounded-lg p-4 shadow hover:shadow-lg transition">
                    <h4 className="text-sm font-semibold text-gray-900">{tip.title}</h4>
                    <p className="mt-1 text-sm text-gray-600">{tip.description}</p>
                  </li>
                ))}
              </ul>
            </div>

            {/* Afgeronde Taken */}
            <div className="px-4 py-6">
              <h3 className="text-lg font-semibold text-gray-900">Afgeronde Taken</h3>
              {suggestions.filter(suggestion => suggestion.completed).length === 0 ? (
                <p className="mt-2 text-sm text-gray-600">Er zijn nog geen afgeronde taken.</p>
              ) : (
                <ul className="mt-4 space-y-4">
                  {suggestions
                    .filter(suggestion => suggestion.completed)
                    .map(suggestion => (
                      <li key={suggestion.id} className="bg-white border border-gray-200 rounded-lg p-4 shadow hover:shadow-lg transition">
                        <p className="text-sm text-gray-900">{suggestion.text}</p>
                        <button
                          type="button"
                          onClick={() => toggleCompletion(suggestion.id)}
                          className="mt-2 text-sm text-blue-500 hover:text-blue-600"
                        >
                          Markeer als niet-afgerond
                        </button>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <Tabbarmobile talentId={talentId} />
      </Layout>
    </>
  );
}