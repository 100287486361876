import { collection, query, getDocs, where, limit, DocumentReference, QueryDocumentSnapshot, SnapshotOptions, orderBy, and, addDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
export type RemoteEvaluatie = {
    aanmaak_datum: Date,
    geschreven_door: DocumentReference,
    omschrijving: string,
    talent_ref: DocumentReference
};

const evaluatieConverter = {
    toFirestore: (evaluatie: RemoteEvaluatie) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteEvaluatie {
        const data = snapshot.data(options)!;
        return {
            aanmaak_datum: data.aanmaak_datum.toDate(),
            geschreven_door: data.geschreven_door,
            omschrijving: data.omschrijving,
            talent_ref: data.talent_ref
        }
    }
}

export default async (dataOwner: DocumentReference, max: number) => {

    const q = query(collection(db, "evaluatie"), where("data_eigenaar_ref", "==", dataOwner), orderBy("aanmaak_datum", "desc"), limit(max)).withConverter(evaluatieConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteEvaluatie[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getEvaluatiesByTalentId = async (reference: DocumentReference, dataOwner: DocumentReference) => {

    const q = query(collection(db, "evaluatie"), and(where("talent_ref", "==", reference), where("data_eigenaar_ref", "==", dataOwner)), orderBy("aanmaak_datum", "desc")).withConverter(evaluatieConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteEvaluatie[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const createEvaluatieDocument = (data: {auteurReference: DocumentReference, content: string, talentReference: DocumentReference, dataOwner: DocumentReference}) => {
    return addDoc(collection(db, "evaluatie"), {aanmaak_datum: new Date(), data_eigenaar_ref: data.dataOwner, geschreven_door: data.auteurReference, omschrijving: data.content, talent_ref: data.talentReference})
}