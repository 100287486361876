'use client'
import Layout from '../../../layout'
import { useState, useEffect } from 'react'
import { Dialog, DialogPanel, Field, Label, Switch } from '@headlessui/react'
import { ArrowUpOnSquareStackIcon, Bars3Icon, BookOpenIcon, UserIcon } from '@heroicons/react/20/solid'
import {
    BellIcon,
    BookmarkIcon,
    BookmarkSquareIcon,
    CreditCardIcon,
    CubeIcon,
    FingerPrintIcon,
    HandRaisedIcon,
    UserCircleIcon,
    UserGroupIcon,
    UserMinusIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { BedrijfModel, getBedrijfByReference } from '../../../domain/bedrijf-utils'


const secondaryNavigation = [
    { name: 'Algemene instellingen', href: '/beheer', icon: UserCircleIcon, current: true },
    { name: 'Trajecten', href: '/beheer/trajecten', icon: BookmarkSquareIcon, current: false },
    { name: 'Talenten', href: '/beheer/talenten', icon: UsersIcon, current: false },
    { name: 'Team', href: '/beheer/team', icon: UserGroupIcon, current: false },
    { name: 'Beveiliging', href: '/beheer/beveiliging', icon: FingerPrintIcon, current: false },
    { name: 'Onboarding', href: '/beheer/onboarding', icon: CubeIcon, current: false },

]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    // const [bedrijven, setBedrijven] = useState<BedrijfModel[]>([]);
    const [bedrijf, setBedrijf] = useState<BedrijfModel[]>([]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const bedrijfData = await getBedrijfByReference(???); 
    //             setBedrijven(bedrijfData || []); 
    //         } catch (error) {
    //             console.error(error); 
    //         }
    //     };
    
    //     fetchData();
    // }, []);
    return (

        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
               {/* {bedrijf && ( */}
            <ul>
                {bedrijf.map((bedrijf) => (
                    <li key={""}>
                        <div className="pb-1 sm:pb-6">
                            <div className="relative h-40 sm:h-56">
                                <img
                                    alt=""
                                    src={bedrijf.logo}
                                    className="absolute h-full w-full object-cover"
                                />
                            </div>
                            <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                                <div className="sm:flex-1">
                                    <div>
                                        <div className="flex items-center">
                                            <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{bedrijf.naam}</h3>
                                            <span className="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400">
                                                <span className="sr-only">Online</span>
                                            </span>
                                        </div>
                                        <p className="text-sm text-gray-500">{bedrijf.locatie}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            {/*   )} */}


            <nav className="flex-none px-4 sm:px-6 lg:px-0">
                <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                    {secondaryNavigation.map((item) => (
                        <li key={item.name}>
                            <a
                                href={item.href}
                                className={classNames(
                                    item.current
                                        ? 'bg-gray-50 text-indigo-600'
                                        : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                    'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6',
                                )}
                            >
                                <item.icon
                                    aria-hidden="true"
                                    className={classNames(
                                        item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                        'h-6 w-6 shrink-0',
                                    )}
                                />
                                {item.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </aside>
    )
}
