import { DocumentReference, getDoc } from 'firebase/firestore';
import { getBedrijfDocumentByReference, getBedrijfReferenceByName, getBedrijfReferenceByReference } from '../datasource/bedrijf-utils';

export type BedrijfModel = {
    reference: DocumentReference,
    locatie: string;
    huisnummer: string;
    logo: string;
    naam: string;
    plaats: string;
    postcode: string;
    soort: null; 
    straat: string;
}

export type LicentienemerModel = {
    reference: DocumentReference,
    naam: string
}

export const getBedrijfByReference = async (werkbedrijfRef: DocumentReference) => {
    const bedrijf = await getBedrijfDocumentByReference(werkbedrijfRef.id)

    if (!bedrijf) {

        return null;
    }
  
    return {
        reference: bedrijf.reference,
        huisnummer: bedrijf.huisnummer,
        logo: bedrijf.logo,
        naam: bedrijf.naam,
        plaats: bedrijf.plaats,
        postcode: bedrijf.postcode,
        soort: null,
        straat: bedrijf.straat,
        locatie: bedrijf.plaats + " " + bedrijf.straat + " " + bedrijf.postcode,
    } as BedrijfModel;
};

export const getBedrijfById = async (id: string) => {
    const bedrijf = await getBedrijfDocumentByReference(id)

    if (!bedrijf) {

        return null;
    }
  
    return {
        reference: bedrijf.reference,
        huisnummer: bedrijf.huisnummer,
        logo: bedrijf.logo,
        naam: bedrijf.naam,
        plaats: bedrijf.plaats,
        postcode: bedrijf.postcode,
        soort: null,
        straat: bedrijf.straat,
        locatie: bedrijf.plaats + " " + bedrijf.straat + " " + bedrijf.postcode,
    };
};

export const getBedrijfByName = async (name: string) => {
    const reference = await getBedrijfReferenceByName(name)

    if (!reference) {

        return Promise.reject("Geen bedrijf gevonden.");
    }
  
    return {
        reference: reference
    };
};

export const getLicentienemerByReference = async (reference: DocumentReference) => {
    const licentienemer = await getBedrijfReferenceByReference(reference)

    if (!licentienemer) {

        return Promise.reject("Geen bedrijf gevonden.");
    }
  
    return {
        reference: licentienemer.reference,
        naam: licentienemer.naam
    } as LicentienemerModel;
};

  
