import { collection, query, getDocs, where, limit, DocumentReference, getDoc, doc, and } from "firebase/firestore";
import { db } from "../utils/firebase";
export type RemoteWerkvloerbegeleider = {
    reference: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,

};

export default async (max: number, dataOwner: DocumentReference) => {

    const q = query(collection(db, "user"), and(where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "werkvloerbegeleider")), limit(max));

    const querySnapshot = await getDocs(q);
    const list: RemoteWerkvloerbegeleider[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push({
            reference: doc.ref,
            achternaam : doc.data().achternaam,
                  email :doc.data().email,
                  gebruikers_rol : doc.data().gebruikers_rol,
                  geslacht : doc.data().geslacht,
                  profiel_foto : doc.data().profiel_foto,
                  telefoon_nummer : doc.data().telefoon_nummer,
                  voornaam : doc.data().voornaam, 
        })
    });

    return list; 
}

export const getWerkvloerbegeleiderById = async (id: DocumentReference | string ) => {

    if(!id ){
        return null;
    }
    const docSnap =  (typeof id === "string" ) ?await getDoc( doc(db, "user", id.toString())):await getDoc(id as DocumentReference);

    /* = await getDoc(id); */
    
    if (docSnap.exists()) {
       return {
        reference: docSnap.ref,
        achternaam : docSnap.data().achternaam,
                  email :docSnap.data().email,
                  gebruikers_rol : docSnap.data().gebruikers_rol,
                  geslacht : docSnap.data().geslacht,
                  profiel_foto : docSnap.data().profiel_foto,
                  telefoon_nummer : docSnap.data().telefoon_nummer,
                  voornaam : docSnap.data().voornaam
    } as RemoteWerkvloerbegeleider;
      } else {
        return null; 
      }
}
