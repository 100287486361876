import {
    BuildingOfficeIcon,
    ChatBubbleLeftIcon,
    CheckCircleIcon,
    CreditCardIcon,
    FolderArrowDownIcon,
    HomeModernIcon,
    UserIcon,
    UsersIcon,
  } from '@heroicons/react/20/solid';
  import {
      ArrowLeftIcon,
    BookOpenIcon,
    CheckBadgeIcon,
    ClockIcon,
    HomeIcon,
    MapIcon,
    UserCircleIcon,
  } from '@heroicons/react/24/outline';
  import { Link, useLocation } from 'react-router-dom';
  
  export default function Example() {
    const location = useLocation();
  
    return (
      <div className="flex h-16 justify-around border-gray-200 bg-white/70 backdrop-blur px-4 sm:hidden z-10">
        <Link
          className={`flex flex-col items-center justify-center ${
            location.pathname === '/talentenhub' ? 'text-blue-600' : 'text-gray-950'
          }`}
          to="/talentenhub"
        >
          <div className="text-center">
            <ArrowLeftIcon
              aria-hidden="true"
              className={`h-5 w-5 mx-auto ${
                location.pathname === '/' ? 'text-blue-600' : 'text-gray-500'
              }`}
            />
            <div className="text-xs text-slate-700">Terug</div>
          </div>
        </Link>
        <Link
          className={`flex flex-col items-center justify-center ${
            location.pathname === '/talentenhub' ? 'text-blue-600' : 'text-gray-950'
          }`}
          to="/talentenhub"
        >
          <div className="text-center">
            <MapIcon
              aria-hidden="true"
              className={`h-5 w-5 mx-auto ${
                location.pathname === '/talentenhub' ? 'text-blue-600' : 'text-gray-500'
              }`}
            />
            <div className="text-xs text-slate-700">Traject</div>
          </div>
        </Link>
        <Link
          className={`flex flex-col items-center justify-center ${
            location.pathname === '/rooster' ? 'text-blue-600' : 'text-gray-950'
          }`}
          to="/rooster"
        >
          <div className="text-center">
            <BookOpenIcon
              aria-hidden="true"
              className={`h-5 w-5 mx-auto ${
                location.pathname === '/rooster' ? 'text-blue-600' : 'text-gray-500'
              }`}
            />
            <div className="text-xs text-slate-700">Documenten</div>
          </div>
        </Link>
        <Link
          className={`flex flex-col items-center justify-center ${
            location.pathname === '/bijenkorf' ? 'text-blue-600' : 'text-gray-950'
          }`}
          to="/bijenkorf"
        >
          <div className="text-center">
            <ClockIcon
              aria-hidden="true"
              className={`h-5 w-5 mx-auto ${
                location.pathname === '/bijenkorf' ? 'text-blue-600' : 'text-gray-500'
              }`}
            />
            <div className="text-xs text-slate-700">Rooster</div>
          </div>
        </Link>
      </div>
    );
  }