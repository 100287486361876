'use client'

import { useEffect, useRef, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { SubmitHandler, useForm } from 'react-hook-form'
import { signInWithMFA } from '../../../utils/auth'
import { useNavigate } from 'react-router-dom'

type Inputs = {
    n1: string,
    n2: string,
    n3: string,
    n4: string,
    n5: string,
    n6: string,
}

export default function MFAAuthValidate({ error, handleMFASuccess }) {
    const [open, setOpen] = useState(true)

    const inputRefs = useRef<Array<HTMLDivElement | null>>([]);

    const {
        register,
        handleSubmit,
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const mfaCode = data.n1 + data.n2 + data.n3 + data.n4 + data.n5 + data.n6
        signInWithMFA(error, mfaCode).then((_) => {
            handleMFASuccess()
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1]?.focus() // Focus naar volgend veld
        }
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                </div>
                                <div className="mt-3 sm:mt-5">
                                    <DialogTitle as="h3" className="text-base text-center font-semibold text-gray-900">
                                        Authenticator instellen
                                    </DialogTitle>

                                    <div>
                                        <p className="text-sm text-gray-500 pt-6">
                                            Response code hieronder invoeren
                                        </p>
                                    </div>
                                    <div className='grid grid-cols-6 gap-4 text-center mt-4 mx-12'>
                                        {Array.from({ length: 6 }).map((_, i) =>{
                                            let { ref, ...rest } = register(`n${i + 1}` as any);
                                            return <input
                                                {...rest}
                                                ref={el => {ref(el); inputRefs.current[i] = el}} 
                                                onChange={(e) => handleInputChange(i, e)}
                                                type="text"
                                                maxLength={1}
                                                pattern="[0-9]"
                                                placeholder="0"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                            />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="submit"
                                    onClick={() => setOpen(false)}
                                    className="inline-flex w-full justify-center rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                >
                                    Activeren
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                >
                                    Annuleren
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </form>
        </Dialog>
    )
}
