import { collection, query, getDocs, where, limit, DocumentReference, getDoc, QueryDocumentSnapshot, SnapshotOptions, addDoc, setDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { NewTrajectModel } from "../domain/traject-utils";
export type RemoteTraject = {
    duur?: number,
    traject_consulent_reference?: DocumentReference,
    aantal_rapportages?: number,
    evaluatie_momenten?: number,
    jobcoach_interventies?: number,
    praktijkverklaring?: boolean,
    talentportfolio?: boolean,
    loonwaardemeting?: boolean,
    gemeente: string,
    naam: string,
    omschrijving: string,
    reference: DocumentReference,

};

const trajectConverter = {
    toFirestore: (traject: RemoteTraject) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteTraject {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            duur: data.duur,
            traject_consulent_reference: data.consulent_reference,
            aantal_rapportages: data.aantal_rapportages,
            evaluatie_momenten: data.evaluatie_momenten,
            jobcoach_interventies: data.jobcoach_interventies,
            praktijkverklaring: data.praktijkverklaring,
            talentportfolio: data.talentportfolio,
            loonwaardemeting: data.loonwaardemeting,
            gemeente: data.gemeente,
            naam: data.naam,
            omschrijving: data.omschrijving,
        }
    }
}

export default async (dataOwner: DocumentReference) => {

    const q = query(collection(db, "traject"), where("data_eigenaar_ref", "==", dataOwner)).withConverter(trajectConverter);
    const querySnapshot = await getDocs(q);
    const list: RemoteTraject[] = []
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list;
}

export const getTrajectDocumentById = async (id?: string) => {
    if (!id) {
        return null;
    }
    const docSnap = await getDoc(doc(db, "traject", id).withConverter(trajectConverter));

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null;
    }
};

export const getTrajectByID = async (ID?: DocumentReference) => {
    if (!ID) {
        return null;
    }
    const docSnap = await getDoc(ID);

    if (docSnap.exists()) {
        return {
            reference: docSnap.ref,
            duur: docSnap.data().duur,
            traject_consulent_reference: docSnap.data().consulent_reference,
            aantal_rapportages: docSnap.data().aantal_rapportages,
            evaluatie_momenten: docSnap.data().evaluatie_momenten,
            jobcoach_interventies: docSnap.data().jobcoach_interventies,
            praktijkverklaring: docSnap.data().praktijkverklaring,
            talentportfolio: docSnap.data().talentportfolio,
            loonwaardemeting: docSnap.data().loonwaardemeting,
            gemeente: docSnap.data().gemeente,
            naam: docSnap.data().naam,
            omschrijving: docSnap.data().omschrijving,
        };
    } else {
        return null;
    }
};

export const updateTrajectDocument = (id: string, data: NewTrajectModel) => {
    return updateDoc(doc(db, "traject", id), data)
}

export const createTrajectDocument = (data: NewTrajectModel) => {
    return addDoc(collection(db, "traject"), data)
}

// return allTrajecten.filter((traject: TrajectModel) =>
//     traject.naam === selectedTraject.naam && traject.gemeente === selectedTraject.gemeente
//   );


