import { Navigate, redirect } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import Navbar from "./Navbar";
import { Analytics } from "@vercel/analytics/react"
import Tabbar from "././components/tabbar/main/Tabbar";


const Layout = ({ children }) => {
  return (
    <>
      <div className="overflow-auto h-screen ">
        <Analytics />
        <Navbar />
        <main className="mx-auto px-4 sm:px-6 h-[calc(100vh-65px) max-w-[2160px] pb-6">{children}</main>
        <Tabbar/>
      </div>
    </>
  )
}

export default Layout