import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { RapportageModel, getRapportages, getRapportageByTalentRef, getRapportageById } from '../../domain/rapportage-utils'
import { useEffect, useState } from 'react'
import { TalentModel } from '../../domain/talent-utils'
import { useNavigate } from 'react-router-dom'

const statuses = {
  Afgerond: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Overtijd: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RapportagesTab(navigate, documenten: {naam: string, aanmaak_datum: Date, id: string, soort: string}[]) {

  const goToDocument = (id: string, soort: string) => {
    switch(soort) {
      case "intake":
        navigate(`talentenhub/intake/detail/${id}`)
        break
      case "rapportage":
        navigate(`/talentenhub/rapportages/detail/${id}`)
        break
    }
  }


  return (
    <ul role="list" className="divide-y divide-gray-100">
      {documenten.map((document) => (
        <li key={document.id} className="flex items-center justify-between gap-x-6 py-5" onClick={(e) => goToDocument(document.id, document.soort)}>
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900">{document.naam}</p>
              <p
                className={classNames(
                  // statuses[project.status],
                  'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                )}
              >
                {/* {project.status} */}
              </p>
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                {/* Moet gesplits worden in datum en tijd */}
                Opleverdatum:  {document.aanmaak_datum.toLocaleDateString()}
              </p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle r={1} cx={1} cy={1} />
              </svg>
              {/* <p className="truncate">Auteur {rapportage.auteur?.naam}</p> */}
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <a
              // href={rapportage.href}
              className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
            >
              {/* Bekijk rapportage<span className="sr-only">, {rapportage.soort}</span> */}
            </a>
            <Menu as="div" className="relative flex-none">
              <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
              </MenuButton>
              {/* <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                  <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                    Edit<span className="sr-only">, {rapportage.soort}</span>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                    Move<span className="sr-only">, {rapportage.soort}</span>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                    Delete<span className="sr-only">, {rapportage.soort}</span>
                  </a>
                </MenuItem>
              </MenuItems> */}
            </Menu>
          </div>
        </li>
      ))}
    </ul>
  )
}
