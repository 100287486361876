import { collection, query, getDocs, where, limit, DocumentReference, getCountFromServer, orderBy, getDoc, QueryDocumentSnapshot, SnapshotOptions, and, doc } from "firebase/firestore";
import { db } from "../utils/firebase";

export type RemoteLopendTraject = {
    reference: DocumentReference,    
    begindatum?: Date,
    consulent_ref: DocumentReference,
    status: string,
    talent_ref: DocumentReference,
    traject_ref: DocumentReference,
    verwachte_einddatum: Date,
    werkbedrijf_ref: DocumentReference,
    werkdagen: string,
    werkvloer_begeleider_ref: DocumentReference
};

const lopendTrajectConverter = {
    toFirestore: (lopendTraject: RemoteLopendTraject) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteLopendTraject {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            begindatum: (data.begindatum) ? data.begindatum.toDate() : data.begindatum,
            consulent_ref: data.consulent_ref,
            status: data.status,
            talent_ref: data.talent_ref,
            traject_ref: data.traject_ref,
            verwachte_einddatum: data.verwachte_einddatum,
            werkbedrijf_ref: data.werkbedrijf_ref,
            werkdagen: data.werkdagen,
            werkvloer_begeleider_ref: data.werkvloer_begeleider_ref,
        }
    }
}

export const getLopendeTrajectenDocuments = async (max: number, dataOwner: DocumentReference, statusFilter?: string) => {
    let q = query(collection(db, "lopend_traject"), where("data_eigenaar_ref", "==", dataOwner), limit(max)).withConverter(lopendTrajectConverter);
    
    if (statusFilter) {
        q = query(collection(db, "lopend_traject"), and(where("data_eigenaar_ref", "==", dataOwner), where("status", "==", statusFilter)), limit(max)).withConverter(lopendTrajectConverter);
    }

    const querySnapshot = await getDocs(q);
    const list: RemoteLopendTraject[] = []
    querySnapshot.forEach((doc) => {
        return list.push(doc.data());
    });

    return list;
}

export const getActieveLopendeTrajectenDocuments = async (dataOwner: DocumentReference) => {
    let q = query(collection(db, "lopend_traject"), and(where("data_eigenaar_ref", "==", dataOwner), where("status", "!=", "Beëindigd"))).withConverter(lopendTrajectConverter)

    const querySnapshot = await getDocs(q);
    const list: RemoteLopendTraject[] = []
    querySnapshot.forEach((doc) => {
        return list.push(doc.data());
    });

    return list;
}

export const getLopendeTrajectenByWerkvloerbegeleider = async (werkvloer_begeleider_ref: DocumentReference, dataOwner: DocumentReference) => {
    let q = query(collection(db, "lopend_traject"), and(where("data_eigenaar_ref", "==", dataOwner), where("werkvloer_begeleider_ref", "==", werkvloer_begeleider_ref))).withConverter(lopendTrajectConverter);


    const querySnapshot = await getDocs(q);
    const list: RemoteLopendTraject[] = []
    querySnapshot.forEach((doc) => {
        return list.push(doc.data());
    });

    return list;
}

export const getLopendTrajectDocumentByReference = async (reference: DocumentReference) => {

    const docSnap = await getDoc(reference);

    if (!docSnap.exists()) return null

    return {
        reference: docSnap.ref,
            begindatum: (docSnap.data().begindatum) ? docSnap.data().begindatum.toDate() : undefined,
            consulent_ref: docSnap.data().consulent_ref,
            status: docSnap.data().status,
            talent_ref: docSnap.data().talent_ref,
            traject_ref: docSnap.data().traject_ref,
            verwachte_einddatum: docSnap.data().verwachte_einddatum,
            werkbedrijf_ref: docSnap.data().werkbedrijf_ref,
            werkdagen: docSnap.data().werkdagen,
            werkvloer_begeleider_ref: docSnap.data().werkvloer_begeleider_ref,
    } as RemoteLopendTraject

}

export const getLopendTrajectDocumentById = async (id: string) => {

    const reference = doc(db, "lopend_traject", id).withConverter(lopendTrajectConverter);
    const docSnap = await getDoc(reference);

    if (!docSnap.exists()) return null

    return docSnap.data()

}

export const getTotalCountOfTalentsByWerkvloerbegeleider = async (werkvloerBegeleiderRefs: DocumentReference[], dataOwner: DocumentReference) => {
    let total = 0
    for (const werkvloerBegeleiderRef of werkvloerBegeleiderRefs) {
        const q = query(collection(db, "lopend_traject"), and(where("data_eigenaar_ref", "==", dataOwner), where("werkvloer_begeleider_ref", "==", werkvloerBegeleiderRef)), orderBy("talent_ref"))
        const snapShot = await getCountFromServer(q)
        total += snapShot.data().count
    }
    return total
}