import { DocumentReference } from "firebase/firestore";
import getAllUsersByCursor, { getAllConsulentenDocuments, getUserByReference, getUserDocumentById, searchUserByName, updateUserByRef } from "../datasource/user-utils";

export type UserModel = {
    ref: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    geboortedatum?: string,
    data_eigenaar_ref?: DocumentReference
}

export default async (userRef: DocumentReference, data: any) => {
    return updateUserByRef(userRef, data)
}

export const getAllUsersByPage = async (user: UserModel, docRef?: DocumentReference) => {
    var list: UserModel[] = []
    const users = await getAllUsersByCursor(25, [], user.data_eigenaar_ref!, docRef)
    users.forEach((user) => {
        list.push({
            ref: user.ref,
            achternaam: user.achternaam,
            email: user.email,
            gebruikers_rol: user.gebruikers_rol,
            geslacht: user.geslacht,
            profiel_foto: user.profiel_foto,
            telefoon_nummer: user.telefoon_nummer,
            voornaam: user.voornaam,
            geboortedatum: user.geboortedatum,
        })
    });
}

export const searchByTerm = async (user: UserModel, term: string) => {
    var list: UserModel[] = []
    const users = await searchUserByName(user.data_eigenaar_ref!, term)
    users.forEach((user) => {
        list.push({
            ref: user.ref,
            achternaam: user.achternaam,
            email: user.email,
            gebruikers_rol: user.gebruikers_rol,
            geslacht: user.geslacht,
            profiel_foto: user.profiel_foto,
            telefoon_nummer: user.telefoon_nummer,
            voornaam: user.voornaam,
            geboortedatum: user.geboortedatum,
        })
    });
    return list
}

//Get user by id
export const getUserById = async (userRef?: DocumentReference): Promise<UserModel | null> => {

    if (!userRef) return null

    const user = await getUserByReference(userRef)

    if (!user) {
        return null
    }

    return {
        ref: user.ref,
        achternaam: user.achternaam,
        email: user.email,
        gebruikers_rol: user.gebruikers_rol,
        geslacht: user.geslacht,
        profiel_foto: user.profiel_foto,
        telefoon_nummer: user.telefoon_nummer,
        voornaam: user.voornaam,
        geboortedatum: user.geboortedatum,
        data_eigenaar_ref: user.data_eigenaar_ref
    };
};

export const _getUserById = async (id?: string) => {

    if (!id) return null

    const user = await getUserDocumentById(id)

    if (!user) {
        return null
    }

    return {
        ref: user.ref,
        achternaam: user.achternaam,
        email: user.email,
        gebruikers_rol: user.gebruikers_rol,
        geslacht: user.geslacht,
        profiel_foto: user.profiel_foto,
        telefoon_nummer: user.telefoon_nummer,
        voornaam: user.voornaam,
        geboortedatum: user.geboortedatum,
        data_eigenaar_ref: user.data_eigenaar_ref
    };
};

export const getAllConsulenten = async (user: UserModel) => {
    const consulenten = await getAllConsulentenDocuments(user?.data_eigenaar_ref!);

    const list: UserModel[] = []

    consulenten.forEach((consulent) => {
        list.push({
            ref: consulent.ref,
            achternaam: consulent.achternaam,
            email: consulent.email,
            gebruikers_rol: consulent.gebruikers_rol,
            geslacht: consulent.geslacht,
            telefoon_nummer: consulent.telefoon_nummer,
            voornaam: consulent.voornaam,
            geboortedatum: consulent.geboortedatum,
            profiel_foto: consulent.profiel_foto,
        })
    })

    return list
}
