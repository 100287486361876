
'use client'

import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogPanel,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react'
import {
  Bars3Icon,
  CalendarDaysIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  MapPinIcon,
  PaperClipIcon,
  UserCircleIcon,
  XMarkIcon as XMarkIconMini,
} from '@heroicons/react/20/solid'
import { BellIcon, XMarkIcon as XMarkIconOutline } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import Layout from '../../../../layout'
import { getRapportageById, RapportageModel, updateRapportageById } from '../../../../domain/rapportage-utils'
import { get } from 'http'
import { useParams } from 'react-router-dom'
import Editor from 'react-simple-wysiwyg';
import { PDFDownloadLink } from '@react-pdf/renderer'
import RapportagePDFFile from '../../../../components/pdf/RapportagePDF'
import Generate from '../../../../components/modal/generate/Generate'
import Trajectfeed from '../../../../components/feed/traject/Trajectfeed'

const invoice = {
  subTotal: '$8,800.00',
  tax: '$1,760.00',
  total: '$10,560.00',
  items: [
    {
      id: 1,
      title: 'Logo redesign',
      description: 'In de afgelopen zes maanden heeft Gabriël een duidelijke positieve ontwikkeling doorgemaakt, zowel op het gebied van zijn taken als stallingbeheerder als in zijn persoonlijke groei, met name in zijn beheersing van de Nederlandse taal.',
      hours: '20.0',
      rate: '$100.00',
      price: '$2,000.00',
    },
    
  ],
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Rapportage() {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  // const [selected, setSelected] = useState(moods[5])

  const [html, setHtml] = useState('my <b>HTML</b>');
  
  function onChange(e) {
    setHtml(e.target.value);
  }

  const { id } = useParams();
  const [rapportage, setRapportage] = useState<RapportageModel>()
  useEffect(()=>{
    getRapportageById(id!.toString(), {talent: true, traject: true, bedrijf: true, consulent: true, auteur: true, leidinggevende: true, lopend_traject: true, licentieemer: true}).then((response) => {
      setRapportage(response)
      setHtml(response?.content)
    }).catch((e) => {
      console.error(e)
    })
    

  }, [])

  const saveRapportage = () => {
    updateRapportageById(id, html).then((_) => {
      console.log("Rapportage opgeslagen")
    }).catch((e) => console.log(e))
  }

  return (
    <>
      <Layout>
        <main className='h-svh overflow-auto'>
          <header className="relative isolate pt-16">
            <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
              <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                <div
                  style={{
                    clipPath:
                      'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                  }}
                  className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                />
              </div>
              <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
            </div>

            <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                <div className="flex items-center gap-x-6 ">
                  <img
                    alt=""
                    src={rapportage?.talent?.profiel_foto}
                    className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10 object-cover"
                  />
                  <h1>
                    <div className="text-sm leading-6 text-gray-500">
                      Document: <span className="text-gray-700">{rapportage?.soort}</span>
                    </div>
                    <div className="mt-1 text-xl font-semibold leading-6 text-gray-900">{rapportage?.talent?.naam}</div>
                  </h1>
                </div>
                <div className="flex items-center gap-x-4 sm:gap-x-6">
                  
                  <a onClick={saveRapportage} href="#" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block">
                    Opslaan
                  </a>
                  <PDFDownloadLink className='rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' document={<RapportagePDFFile content={rapportage?.content}/>} fileName={rapportage?.soort}>
                  Download
                  </PDFDownloadLink>
                  

                  <Menu as="div" className="relative sm:hidden">
                    <MenuButton className="-m-3 block p-3">
                      <span className="sr-only">More</span>
                      <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                    </MenuButton>

                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <MenuItem>
                        <button
                          type="button"
                          className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        >
                          Copy URL
                        </button>
                      </MenuItem>
                      <MenuItem>
                        <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                          Bewerken
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 ">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {/* Invoice summary */}
              <div className="lg:col-start-3 lg:row-end-1">
                <h2 className="sr-only">Summary</h2>
                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                  <dl className="flex flex-wrap">
                    <div className="flex-auto pl-6 pt-6">
                      <dt className="text-sm font-semibold leading-6 text-gray-900">Rijswijk</dt>
                      <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">{rapportage?.traject?.naam}</dd>
                    </div>
                    <div className="flex-none self-end px-6 pt-4">
                      <dt className="sr-only">Status</dt>
                      <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                        {rapportage?.lopend_traject?.status}
                      </dd>
                    </div>
                    <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                      <dt className="flex-none">
                        <span className="sr-only">Client</span>
                        <UserCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm font-medium leading-6 text-gray-900">{rapportage?.auteur?.naam}</dd>
                    </div>
                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Due date</span>
                        <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        {rapportage?.aanmaakdatum.toLocaleDateString()}
                      </dd>
                    </div>
                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Status</span>
                        <MapPinIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">{rapportage?.bedrijf?.naam}</dd>
                    </div>
                  </dl>
                  <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                      Document afdrukken <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* Invoice */}
              <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
                <h2 className="text-base font-semibold leading-6 text-gray-900">{rapportage?.soort}</h2>
                <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                  <div className="sm:pr-4">
                    <dt className="inline text-gray-500">Gemaakt op </dt>{rapportage?.aanmaakdatum.toLocaleDateString()}
                  </div>
                  <div className="mt-2 sm:mt-0 sm:pl-4">
                    <dt className="inline text-gray-500">Startdatum </dt>{rapportage?.startdatum.toLocaleDateString()}
                    <dd className="inline text-gray-700">
                      
                    </dd>
                  </div>
                  <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                    <dt className="font-semibold text-gray-900">{rapportage?.licentienemer}</dt>
                    <dd className="mt-2 text-gray-500">
                      <span className="font-medium text-gray-900">Auteur {rapportage?.auteur?.naam}</span>
                      <br />
                      Werkbedrijf {rapportage?.bedrijf?.naam}
                      <br />
                      Leidinggevende {rapportage?.leidinggevende?.naam}
                    </dd>
                  </div>
                  <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                    <dt className="font-semibold text-gray-900">{rapportage?.soort}</dt>
                    <dd className="mt-2 text-gray-500">
                      <span className="font-medium text-gray-900">Consulent {rapportage?.consulent?.naam}</span>
                      <br />
                      Traject {rapportage?.traject?.naam}
                      <br />
                    </dd>
                  </div>
                </dl>
                <table className="mt-10 w-full whitespace-nowrap text-left text-sm leading-6">
                  <colgroup>
                    <col className="w-full" />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                      <th scope="col" className="px-0 py-3 font-semibold">
                        Verslag
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody className='text-sm leading-6 text-gray-500 text-wrap'>
                  <Editor value={html} onChange={onChange} />
                  </tbody>
                </table>
              </div>

              <Trajectfeed/>
            </div>
          </div>
        </main>
      </Layout>
    </>

  )
}
