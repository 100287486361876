import { Button, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import Layout from '../../../layout'
import Talenten from '../../../components/subnav/talenten/talenten'
import { getIntakes } from '../../../domain/intake-utils'
import { useEffect, useState } from 'react'
import { AanmeldingModel, afmeldenAanmelding, getAanmeldingen } from '../../../domain/aanmelding-utils'
import { useAuth } from '../../../context/AuthContext'
import { AfmeldButton } from '../../../components/button/afmeld-button'
import { Link } from 'react-router-dom'

const statuses = {
    Nieuw: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
const projects = [
    {
        id: 1,
        name: 'Selcheley Michiel',
        href: '/talentenhub/intakeformulier',
        status: 'Nieuw',
        createdBy: 'Leslie Alexander',
        dueDate: 'March 17, 2023',
        dueDateTime: '2023-03-17T00:00Z',
    },
    {
        id: 2,
        name: 'New benefits plan',
        href: '#',
        status: 'Nieuw',
        createdBy: 'Leslie Alexander',
        dueDate: 'May 5, 2023',
        dueDateTime: '2023-05-05T00:00Z',
    },
    {
        id: 3,
        name: 'Onboarding emails',
        href: '#',
        status: 'In progress',
        createdBy: 'Courtney Henry',
        dueDate: 'May 25, 2023',
        dueDateTime: '2023-05-25T00:00Z',
    },
    {
        id: 4,
        name: 'iOS app',
        href: '#',
        status: 'In progress',
        createdBy: 'Leonard Krasner',
        dueDate: 'June 7, 2023',
        dueDateTime: '2023-06-07T00:00Z',
    },
    {
        id: 5,
        name: 'Marketing site redesign',
        href: '#',
        status: 'Archived',
        createdBy: 'Courtney Henry',
        dueDate: 'June 10, 2023',
        dueDateTime: '2023-06-10T00:00Z',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Aanmeldingen() {

    const [aanmeldingen, setAanmeldingen] = useState<AanmeldingModel[]>([])
    const {user} = useAuth()

    useEffect(() => {
        getAanmeldingen(user!, {aangemaaktDoor: true}).then((response) => {
            setAanmeldingen(response)
        })

    }, [])

    const afmelden = (index) => {
        afmeldenAanmelding(aanmeldingen[Number(index)].id).then((_) => {
            const arr = [...aanmeldingen]
            delete arr[Number(index)]
            setAanmeldingen(arr)  
        })
    }

    return (
        <Layout>

<Talenten/>
            <ul role="list" className="mx-auto max-w-7xl divide-y divide-gray-100 ">
                {aanmeldingen.map((aanmelding, index) => (
                    <li key={aanmelding.id} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                        <Link to={{ pathname: `/talentenhub/aanmeldingdocument/${aanmelding.id}` }}>
                            <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{aanmelding.volledige_naam}</p>
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="whitespace-nowrap">
                                    Binnengekomen op {aanmelding.aanmaakDatum.toLocaleDateString()}
                                </p>
                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                    <circle r={1} cx={1} cy={1} />
                                </svg>
                                <p className="truncate">Casemanager: {aanmelding.aangemaakt_door?.volledige_naam}</p>
                            </div>
                            </Link>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                            <a
                                href={`/talentenhub/aanmeldingdocument/${aanmelding.id}`}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Bekijk aanmelding
                            </a>
                            <a
                                href={`mailto:${aanmelding.email}`}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Talent uitnodigen
                            </a>
                            <a
                                href={`/talentenhub/intakeformulier/${aanmelding.id}`}
                                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                                Intake starten
                            </a>
                            <AfmeldButton onClick={(e) => afmelden(index)}/>
                        </div>
                    </li>
                ))}
            </ul>
        </Layout>
    )
}