'use client'

import { Fragment, useEffect, useState } from 'react'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogBackdrop, DialogPanel, DialogTitle, Menu, MenuButton, MenuItem, MenuItems, Radio, RadioGroup } from '@headlessui/react'
import {
  AcademicCapIcon,
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  BanknotesIcon,
  Bars3Icon,
  CheckBadgeIcon,
  CheckIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
  NewspaperIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusSmallIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from '@heroicons/react/20/solid'
import { BellIcon, DocumentMagnifyingGlassIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from './logo512.png'
import Nieuw from './components/nieuw/Nieuw'
import { searchByTerm, UserModel } from './domain/user-utils'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from './context/AuthContext'

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Talenten', href: '/talentenhub' },
  { name: 'Rooster', href: '/rooster' },
  { name: 'Trajecten', href: '/trajecten' },
  { name: 'Bijenkorf', href: '/bijenkorf' },

]

const actions = [
  {
    title: 'Nieuwe aanmelding',
    href: '/talentenhub/aanmeldingformulier',
    icon: ClockIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    desc: 'Registreer een nieuw talent en leg basisinformatie vast voor een persoonlijk begeleidingstraject.',
  },
  {
    title: 'Intake starten',
    href: '/talentenhub/intakeformulier',
    icon: NewspaperIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    desc: 'Start het intakeproces om doelen, uitdagingen en ambities te bespreken.',
  },
  {
    title: 'Nieuwe rapportage',
    href: '/talentenhub/rapportageformulier',
    icon: CheckBadgeIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    desc: 'Creëer een voortgangsrapport voor evaluatie en het delen van updates.',
  },
  {
    title: 'Afspraak plannen (binnenkort)',
    href: '#',
    icon: UsersIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    desc: 'Plan en beheer afspraken met het talent voor begeleiding en evaluatie.',
  },

  {
    title: 'Update schrijven (binnenkort)',
    href: '#',
    icon: PencilSquareIcon,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
    desc: 'Voeg regelmatige updates toe over de voortgang en motivatie van het talent.',
  },
  {
    title: 'Opleiding activeren (binnenkort)',
    href: '#',
    icon: AcademicCapIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    desc: 'Activeer en monitor trainingsmodules en leermaterialen voor het talent.',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)





  const [openNieuw, setOpenNieuw] = useState(false)
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)
  const [recent, setRecent] = useState<UserModel[]>([])
  const [users, setUsers] = useState<UserModel[]>([])
  const location = useLocation();

  const navigate = useNavigate();

  function handleClick(id) {
    navigate("/talentenhub/" + id);
  }

  const { user, logOut } = useAuth()


  useEffect(() => {
    if (query) {
      searchByTerm(user!, query).then((response) => {
        setUsers(response)
        setRecent(response)
      })
    }
  }, [query])

  const logOutAction = () => {
    logOut().then((_) => {
      navigate("/")
    }).catch((e) => console.log(e))
  }

  return (
    <>
      <header className="inset-x-0 top-0 z-50 flex h-16 bg-white/20 sticky top-0 z-10 backdrop-blur">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 items-center gap-x-6">
            {/* <button type="button" onClick={() => setMobileMenuOpen(true)} className="-m-3 p-3 md:hidden">
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-5 w-5 text-gray-900" />
            </button> */}
            <Link to={'/'}>
              <img
                alt="Bloeiindex"
                src={Logo}
                className="h-8 w-auto"
              />
            </Link>
          </div>

          <nav className="hidden md:flex md:gap-x-11 text-base md:font-semibold md:leading-6 md:text-gray-700">
            {navigation.map((item, itemIdx) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  location.pathname === item.href ? 'text-sky-400' : 'hover:text-sky-400',
                  'text-gray-700'
                )}
              >
                {item.name}
              </Link>
            ))}
          </nav>

          <div className="flex flex-1 items-center justify-end gap-x-8">
            

            <div className="flex flex-1 items-center justify-end gap-x-6">
              <a href="#" className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900 hover:text-sky-400" onClick={(e) => setOpen(true)}>
                Zoeken
              </a>
              <a
                href="#"
                onClick={(e) => setOpenNieuw(true)}
                className="rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Nieuw
              </a>
            </div>


            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900  hover:bg-gray-50">
                <div className="-m-1.5 p-1.5">
                <span className="sr-only">Your profile</span>
                <img
                  alt=""
                  src={user?.profiel_foto ?? "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/hOclsK0v6r1MxJZCAkio/pub/5SrfjlJfSmCcc7l8fljJ.png"}
                  className="h-8 w-8 rounded-full bg-gray-800"
                />
              </div>
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="px-4 py-3">
                  <p className="text-sm">Ingelogd als</p>
                  <p className="truncate text-sm font-medium text-gray-900">{user?.email}</p>
                </div>
                <div className="py-1">
                  <MenuItem>
                    <a
                      href="/beheer"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Account instellingen
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/beheer/trajecten"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Trajecten
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/beheer/talenten"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Talenten
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/beheer/Team"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Team
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/beheer/beveiliging"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Beveiliging
                    </a>
                  </MenuItem>
                </div>
                <div className="py-1">
                  <form onSubmit={logOutAction} method="POST">
                    <MenuItem>
                      <button
                        type="submit"
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                      >
                        Uitloggen
                      </button>
                    </MenuItem>
                  </form>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </div>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
            <div className="-ml-0.5 flex h-16 items-center gap-x-6">
              <button type="button" onClick={() => setMobileMenuOpen(false)} className="-m-2.5 p-2.5 text-gray-700">
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
              <div className="-ml-0.5">
                <a href="#" className="-m-1.5 block p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    alt=""
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    className="h-8 w-auto"
                  />
                </a>
              </div>
            </div>
            <div className="mt-2 space-y-2">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </DialogPanel>
        </Dialog>
      </header>





      {/* Nieuw dialog */}
      <Dialog as='div' open={openNieuw} onClose={setOpenNieuw} className="relative z-10 ">
        <DialogBackdrop
          transition
          className="backdrop-blur-sm fixed inset-0 bg-white bg-opacity-25 backdrop-blur-md transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto " >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >


              <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                {actions.map((action, actionIdx) => (
                  <div
                    key={action.title}
                    className={classNames(
                      actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                      actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                      actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                      actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                      'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                    )}
                  >
                    <div>
                      <span
                        className={classNames(
                          action.iconBackground,
                          action.iconForeground,
                          'inline-flex rounded-lg p-3 ring-4 ring-white',
                        )}
                      >
                        <action.icon aria-hidden="true" className="h-6 w-6" />
                      </span>
                    </div>
                    <div className="mt-8">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        <a href={action.href} className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span aria-hidden="true" className="absolute inset-0" />
                          {action.title}
                        </a>
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">
                        {action.desc}
                      </p>
                    </div>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                    >
                      <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>



              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => setOpenNieuw(false)}
                  className="inline-flex w-full justify-center rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Terug naar de Bloeiindex
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>





      {/* Search Dialog*/}
      <Dialog
        className="relative z-10"
        open={open}
        onClose={() => {
          setOpen(false)
          setQuery('')
        }}
      >
        <DialogBackdrop
          transition
          className="backdrop-blur-sm fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20 ">
          <DialogPanel
            transition
            className="mx-auto rounded-lg max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <Combobox
              onChange={(person) => {
                if (person) {
                  window.location = person.url
                }
              }}
            >
              {({ activeOption }) => (
                <>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <ComboboxInput
                      autoFocus
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Zoeken in de Bloeiindex..."
                      onChange={(event) => setQuery(event.target.value)}
                      onBlur={() => setQuery('')}
                    />
                  </div>

                  {(query === '' || users.length > 0) && (
                    <ComboboxOptions as="div" static hold className="flex transform-gpu divide-x divide-gray-100">
                      <div
                        className={classNames(
                          'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                          activeOption && 'sm:h-96',
                        )}
                      >
                        {query === '' && (
                          <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">Recentelijk gezocht</h2>
                        )}
                        <div className="-mx-2 text-sm text-gray-700">
                          {(query === '' ? recent : users).map((person) => (
                            <ComboboxOption
                              as="div"
                              key={person.ref.id}
                              value={person}
                              className="group flex cursor-default select-none items-center rounded-md p-2 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              <img src={person.profiel_foto} alt="" className="h-6 w-6 flex-none rounded-full" />
                              <span className="ml-3 flex-auto truncate">{person.voornaam} {person.achternaam}</span>
                              <ChevronRightIcon
                                className="ml-3 hidden h-5 w-5 flex-none text-gray-400 group-data-[focus]:block"
                                aria-hidden="true"
                              />
                            </ComboboxOption>
                          ))}
                        </div>
                      </div>

                      {activeOption && (<>
                        <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                          <div className="flex-none p-6 text-center">
                            <img src={activeOption.profiel_foto} alt="" className="mx-auto h-16 w-16 rounded-full" />
                            <h2 className="mt-3 font-semibold text-gray-900">{activeOption.voornaam}</h2>
                            <p className="text-sm leading-6 text-gray-500">{activeOption.role}</p>
                          </div>
                          <div className="flex flex-auto flex-col justify-between p-6">
                            <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                              <dt className="col-end-1 font-semibold text-gray-900">Telefoon</dt>
                              <dd>{activeOption.telefoon_nummer}</dd>
                              <dt className="col-end-1 font-semibold text-gray-900">Consulent</dt>
                              <dd className="truncate">
                                <a href={activeOption.url} className="text-indigo-600 underline">
                                  {activeOption.url}
                                </a>
                              </dd>
                              <dt className="col-end-1 font-semibold text-gray-900">Email</dt>
                              <dd className="truncate">
                                <a href={`mailto:${activeOption.email}`} className="text-indigo-600 underline">
                                  {activeOption.email}
                                </a>
                              </dd>
                            </dl>
                            {activeOption.gebruikers_rol == "talent" &&
                              <button
                                type="button"
                                onClick={(e) => handleClick(activeOption.ref.id)}
                                className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Bekijk Talent
                              </button>}
                          </div>
                        </div></>
                      )}
                    </ComboboxOptions>
                  )}

                  {query !== '' && users.length === 0 && (
                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                      <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">No people found</p>
                      <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                    </div>
                  )}
                </>
              )}
            </Combobox>
          </DialogPanel>
        </div>
      </Dialog>





      {/* Action bar */}



    </>
  )
}
